import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [resend, setResend] = useState(false);
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    console.log("handleChange called, value:", value);
    if (errors && errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  };

  // useEffect(() => {
  //   if (!otpSent && !userId) {
  //     navigate("/LogIn");
  //   }
  // }, [otpSent, userId, navigate]);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    // Email validation
    if (!email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSendOTP = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const payload = { email: email };
      //console.log("Sending payload:", payload);
      const response = await postData("auth/senOTPRequest", payload);
      //setOtpSent(true);
      setUserId(response.data.id);

      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP Has Been Sent To Your Mail",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
        setOtpSent(true);
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed To Send OTP. Please Try Again.",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Failed To Send OTP. Please Try Again.", "error");
    }
  };

  const handleOTPChange = (e) => {
    const value = e.target.value;
    // Use a regular expression to allow only digits
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }
  };
  const handleVerifyOtp = async () => {
    if (!email) {
      Swal.fire("Error", "Email Is Required.", "error");
      return;
    }

    if (!otp) {
      Swal.fire("Error", "OTP Is Required.", "error");
      return;
    } else if (!/^\d{4}$/.test(otp)) {
      Swal.fire("Error", "OTP Must Be A 4-digit Number.", "error");
      return;
    }

    try {
      const payload = { email, otp, id: userId };
      const response = await postData("auth/OTPVerifyRequest", payload);
      //console.log("Verifying OTP with payload:", payload);

      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP verified Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
        navigate(`/ChangePassword?userId=${response.data.id}`);
      } else {
        //Swal.fire('Error', response.message || 'Incorrect OTP. Please try again.', 'error');
        Swal.fire({
          title: "Error",
          text: "Incorrect OTP. Please Try Again",
          icon: "error",
          timer: 2000,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire("Error", "OTP Verification Failed. Please Try Again.", "error");
    }
  };

  const handleResendOtp = async () => {
    setResend(true);
    setTimeout(async () => {
      try {
        const payload = { email };
        const response = await postData("auth/senOTPRequest", payload);

        if (response.code === 1) {
          Swal.fire({
            title: "Success",
            text: "OTP Has Been Sent To Your Mail",
            icon: "success",
            timer: 2000,
            showConfirmButton: true,
          });
        } else {
          // Swal.fire('Error', response.message || 'Failed to resend OTP. Please try again.', 'error');
          Swal.fire({
            title: "Error",
            text: "Failed To Resend OTP. Please Try Again",
            icon: "error",
            timer: 2000,
            showConfirmButton: true,
          });
        }
      } catch (error) {
        Swal.fire("Error", "Failed To Resend OTP. Please Try Again.", "error");
      }
      setResend(false);
    }, 1000);
  };

  const handleCancel = () => {
    setEmail("");
    setErrors({});
    navigate("/LogIn");
  };

  return (
    <section className="w-full">
      <div
        className="w-full h-dvh bg-red-300 bg-no-repeat bg-left bg-cover bg-scroll md:flex md:items-center md:gap-4 px-4 py-4"
        style={{ backgroundImage: "url(./login-bg.jpg)" }}
      >
        <div className="w-2/5 xss:w-full"></div>
        <div className="md:w-3/5 w-full   min-h-80 bg-gray-100 rounded-lg shadow-lg px-4 py-6 mt-20 md:mt-0 ">
          <div className="flex flex-direction-row justify-center gap-3">
            <Link to="/">
              <img
                src={"./samara-logo.png"}
                width="300px"
                alt="logo"
                className="max-w-full"
              />
            </Link>
          </div>

          <div className="w-full mt-4">
            <h1 className="font-fontPoppins text-lg font-semibold text-left">
              Forgot Password
            </h1>
            <label
              htmlFor="email"
              className="w-full font-fontPoppins text-sm font-medium mb-1"
            >
              Email
            </label>
            <input
              id="email"
              className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
              type="text"
              name="email"
              value={email}
              placeholder="Enter your Email"
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-red-500 text-md">{errors.email}</p>
            )}
          </div>

          {otpSent && (
            <div className="w-full mt-4">
              <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                OTP
              </label>
              <input
                className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                type="text"
                name="otp"
                value={otp}
                placeholder="Enter OTP"
                onChange={handleOTPChange}
              />
            </div>
          )}

          <div className="w-full text-center mt-4 flex justify-center gap-2">
            {otpSent ? (
              <>
                <button
                  type="button"
                  className="w-24 py-2 bg-blueColor rounded-full font-fontPoppins text-xs font-medium text-white hover:bg-opacity-80"
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </button>
                <button
                  type="button"
                  className="w-24 py-2 text-blue-700 bg-white border border-blue-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-blue-100 font-fontPoppins text-xs font-medium rounded-full focus:border-blue-500 focus:ring-blue-500"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </button>
                <button
                  type="button"
                  className="w-24 py-2 text-blue-700 bg-white border border-blue-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-blue-100 font-fontPoppins text-xs font-medium rounded-full focus:border-blue-500 focus:ring-blue-500"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  type="submit"
                  className="w-24 py-2 bg-blueColor rounded-full font-fontPoppins text-xs font-medium text-white hover:bg-opacity-80"
                  onClick={handleSendOTP}
                >
                  Send OTP
                </button>
                <button
                  type="button"
                  className="w-24 py-2 text-blue-700 bg-white border border-blue-500 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-blue-100 font-fontPoppins text-xs font-medium rounded-full focus:border-blue-500 focus:ring-blue-500"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </>
            )}
          </div>

          <div className="w-full text-center flex justify-center pt-2"></div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
