// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { postData } from "../../Networking/Api";
// import Header from "../Header";
// //import ThirdPartyHeader from "./ThirdPartyHeader";
// import Swal from "sweetalert2";
// //import ThirdPartyFooter from "./ThirdPartyFooter";
// import Loader from "../Loader";
// import ClinicBookingHeader from "./ClinicBookingHeader";
// import ClinicBookingFooter from "./ClinicBookingFooter";
// import Loader2 from "../Loader2";
// import { faL } from "@fortawesome/free-solid-svg-icons";
// import ClinicBookingHeader2 from "./ClinicBookingHeader2";
// import moment from "moment-timezone";

// function ClinicBookedAppointmentDetails() {
//   const { viewId } = useParams();
//   const pathlocation = useLocation();
//   const showFirstHeader =
//     pathlocation.pathname === "/bookappointmentDetails/:viewId";
//   const [logoImage, setLogoImage] = useState("");
//   const [locationDetails, setLocationDetails] = useState({});
//   const [Phone, setPhone] = useState("");
//   const [Street, setStreet] = useState("");
//   const [address, setAddress] = useState("");
//   const [clinicname, setClinicName] = useState("");
//   const [practionIdlocation, setPractionIdlocation] = useState();
//   const [loading, setLoading] = useState(true);

//   console.log(viewId);

//   useEffect(() => {
//     console.log("Initial Render, View ID:", viewId);
//     if (viewId) {
//       getRescheduleAppointmentData(viewId);
//     }
//   }, []);
//   const getRescheduleAppointmentData = async (viewId) => {
//     console.log("viewId", viewId);

//     // if (!viewId) {
//     //   return;
//     // }
//     try {
//       setLoading(true);

//       const payload = {
//         modelName: "patient_appointments",
//         whereCondition: {
//           id: viewId,
//         },
//         relations: [
//           {
//             module: "practitioners",
//             subModule: "clinic_locations",
//           },
//           // {
//           //   module: "appointment_type",
//           // },
//           {
//             module: "practitioner_calendars",
//           },
//           {
//             module: "clinics",
//           },
//         ],
//       };
//       const response = await postData("/masters/getMasterList", payload);
//       console.log("rescheduleres", response);
//       console.log(response.data[0].rescheduled_ref);

//       const logoUrl = response.data[0].clinics.logo_image;
//       setLogoImage(logoUrl);
//       setClinicName(response.data[0].clinics.name);
//       getPractitonerLocationData(response.data[0].clinic_location_id);
//       setPractionIdlocation(response.data[0].clinic_location_id);
//       console.log("data", response.data[0].clinic_location_id);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };

//   const getPractitonerLocationData = async (practionerLocationId) => {
//     if (practionerLocationId) {
//       try {
//         setLoading(true);
//         const payload = {
//           modelName: "clinic_locations",
//           whereCondition: {
//             id: practionerLocationId,
//           },
//         };
//         const response = await postData("/masters/getMasterList", payload);
//         const clinicData = response.data[0];
//         console.log("res", practionerLocationId, clinicData);
//         setStreet(clinicData.street);
//         setPhone(clinicData.phone);
//         const { street, city, state, country, zipcode } = clinicData;
//         const fullAddress = `${city}, ${state}, ${country}, ${zipcode}`;
//         setAddress(fullAddress);
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         console.error("Error fetching insurance options:", error);
//       }
//     }
//   };
//   return (
//     <>
//       <ClinicBookingHeader2
//         logoImage={logoImage}
//         Phone={Phone}
//         Street={Street}
//         address={address}
//         clinicname={clinicname}
//         // practionIdlocation={practionIdlocation}
//       />
//       <YourBookingDetails
//         loading={loading}
//         setLoading={setLoading}
//         address={address}
//         Phone={Phone}
//         Street={Street}
//       />

//       <ClinicBookingFooter />
//     </>
//   );
// }

// function YourBookingDetails({ loading, setLoading, address, Phone, Street }) {
//   const location = useLocation();
//   const { patientType, insuranceType, appointmentType, type, id, viewId } =
//     useParams();

//   const navigate = useNavigate();
//   const [viewDetails, setViewDetails] = useState("");
//   const [appoinmentName, setAppoinmentName] = useState("");
//   const [insuranceName, setInsuranceName] = useState("");
//   // const [address, setAddress] = useState("");
//   const [selectedAppointment, setSelectedAppointment] = useState([]);
//   const [patientTypeName, setPatientTypeName] = useState("");
//   const [patientId, setPatientId] = useState("");
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

//   const [iplocation, setIpLocation] = useState("");
//   const [timeZone, setTimeZone] = useState("");
//   const [locationName, setLocationName] = useState("");

//   const [patientTypeId, setPatientTypeId] = useState("");
//   const [clinicinsuranceId, setClinicInsuranceId] = useState("");
//   const [serviceId, setServiceId] = useState("");
//   const [carrierName, setCarrierName] = useState("");
//   const [insurancePlanName, setInsurancePlanName] = useState("");
//   const [patientAppointmentId, setPatientAppointmentId] = useState("");
//   const [status, setStatus] = useState("");
//   const [rescheduleref, setRescheduleref] = useState(null);
//   const [heading, setHeading] = useState("");

//   const [insurance, setInsurance] = useState("");
//   useEffect(() => {
//     getAppointmentDetails(viewId);
//   }, [viewId]);

//   useEffect(() => {
//     const getLocationFromIP = async () => {
//       try {
//         // setLoading(true);
//         var currentTimeZone = localStorage.getItem("currentTimeZone");
//         // alert(currentTimeZone)
//         if (
//           currentTimeZone == "null" ||
//           currentTimeZone == null ||
//           currentTimeZone == ""
//         ) {
//           // alert("if")
//           try {
//             // setLoading(true);
//             const response = await fetch("https://ipapi.co/json/");
//             if (!response.ok) {
//               // setLoading(false);
//               throw new Error("Network response was not ok");
//             }
//             const IpData = await response.json();

//             setIpLocation({
//               lat: IpData.latitude,
//               lon: IpData.longitude,
//             });

//             const locationName = `${IpData.city}, ${IpData.region_code}`;
//             setLocationName(locationName);

//             const timeZone = `${IpData.utc_offset}, ${IpData.timezone}`;
//             setTimeZone(timeZone);
//             // alert(timeZone)
//             localStorage.setItem("currentTimeZone", timeZone);
//           } catch (error) {
//             console.error("Error fetching data:", error);
//           }
//         } else {
//           // alert("else")
//           setTimeZone(JSON.parse(currentTimeZone));
//           // setLoading(false);
//         }
//       } catch (error) {
//         console.error("Error fetching IP location:", error);
//         // setLoading(false);
//       }
//     };
//     getLocationFromIP();
//   }, []);

//   const getAppointmentDetails = async (viewId) => {
//     console.log("viewId2", viewId);

//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "patient_appointments",
//         whereCondition: {
//           id: viewId,
//         },
//         relations: [
//           {
//             module: "clinics",
//           },
//           {
//             module: "patients",
//           },
//           {
//             module: "practitioners",
//             subModule: "clinic_locations",
//           },
//           // {
//           //   module: "appointment_type",
//           // },
//         ],
//       };

//       const apiResponse = await postData("masters/getMasterList", payload);

//       if (apiResponse.code == 1) {
//         setLoading(false);
//         setViewDetails(apiResponse.data[0]);
//         console.log("view", viewDetails);
//         setHeading(
//           determineHeading(
//             apiResponse.data[0].status,
//             apiResponse.data[0].rescheduled_ref
//           )
//         );
//         setPatientId(apiResponse.data[0].patient_id);
//         setPatientAppointmentId(apiResponse.data[0].id);
//         setPatientTypeId(apiResponse.data[0].patient_type_id);
//         setClinicInsuranceId(apiResponse.data[0].clinic_insurance_id);
//         setServiceId(apiResponse.data[0].clinic_service_id);
//         setInsurance(apiResponse.data[0].insurance);
//       } else {
//         setLoading(false);
//         return null;
//       }
//     } catch (error) {
//       console.error("Error searching provider:", error);
//       return null;
//     }
//   };
//   const determineHeading = (status, rescheduleref) => {
//     console.log(status, rescheduleref, "data");
//     if (status === "Booked") {
//       return !rescheduleref
//         ? "Your Appointment Is Booked!"
//         : "Your Appointment Is Rescheduled!";
//     } else if (status == "Cancelled") {
//       return "Reschedule Your Appointment!";
//     }
//     return "";
//   };
//   useEffect(() => {
//     if (clinicinsuranceId) {
//       getClinicInsuranceData(clinicinsuranceId);
//     }
//   }, [clinicinsuranceId]);
//   useEffect(() => {
//     if (serviceId) {
//       getClinicServiceData(serviceId);
//     }
//   }, [serviceId]);

//   const getClinicInsuranceData = async (clinicinsuranceId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_insurances",
//         whereCondition: {
//           id: clinicinsuranceId,
//         },
//         relations: [
//           {
//             module: "insurance_masters",
//           },
//           {
//             module: "health_insurance_carrier_master",
//           },
//         ],
//       };
//       const response = await postData("/masters/getMasterList", payload);

//       setCarrierName(
//         response.data[0].health_insurance_carrier_master
//           .health_insurance_carrier_name
//       );
//       setInsurancePlanName(response.data[0].insurance_masters.insurance_name);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };
//   const getClinicServiceData = async (serviceId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_services",
//         whereCondition: {
//           id: serviceId,
//         },
//       };
//       const response = await postData("/masters/getMasterList", payload);

//       setAppoinmentName(response.data[0]);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     const selectedAppointmentData = JSON.parse(
//       localStorage.getItem("selectedAppointment")
//     );
//     if (selectedAppointmentData) {
//       setSelectedAppointment(selectedAppointmentData);
//     }
//     setLoading(false);
//   }, []);

//   // useEffect(() => {
//   //   const getClinicDetails = async () => {
//   //     try {
//   //       setLoading(true);
//   //       const payload = {
//   //         modelName: "clinics",
//   //         whereCondition: {
//   //           id: viewDetails.clinic_id,
//   //         },
//   //       };
//   //       const response = await postData("masters/getMasterList", payload);

//   //       const clinicData = response.data[0];
//   //       const addressParts = [
//   //         clinicData.street,
//   //         clinicData.address,
//   //         clinicData.city,
//   //         clinicData.state,

//   //         clinicData.zipcode,
//   //       ];

//   //       const formattedAddress = addressParts.filter(Boolean).join(", ");
//   //       setAddress(formattedAddress);
//   //       setLoading(false);
//   //     } catch (error) {
//   //       setLoading(false);
//   //       console.error("Error fetching clinic details:", error);
//   //     }
//   //   };
//   //   getClinicDetails();
//   // }, [viewDetails]);

//   function formatAppointmentDate(dateString) {
//     console.log("dateString---->", dateString);
//     const currentTimeZone = localStorage.getItem("currentTimeZone");
//     var patientTimeZone = currentTimeZone.split(",")[1];
//     var timeZone = patientTimeZone.trim();
//     timeZone = "America/New_York";
//     const utcTimestamp = dateString;
//     const localTimestamp = moment
//       .utc(utcTimestamp)
//       .tz("America/New_York")
//       .format("ddd, DD MMM hh:mm A");
//     console.log("Retrieved local timestamp:", localTimestamp);
//     return localTimestamp;
//     const date = new Date(dateString);
//     const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//     const months = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];

//     const dayName = days[date.getUTCDay()];
//     const day = date.getUTCDate();
//     const monthName = months[date.getUTCMonth()];
//     const hours = date.getUTCHours();
//     const minutes = date.getUTCMinutes();

//     const formattedHours = hours % 12 || 12;
//     const formattedMinutes = minutes.toString().padStart(2, "0");
//     const ampm = hours >= 12 ? "PM" : "AM";

//     return `${dayName}, ${day} ${monthName} ${formattedHours}:${formattedMinutes}${ampm}`;
//   }
//   const handleBookAnotherAppointment = () => {
//     localStorage.removeItem("selectedSlot");
//     localStorage.removeItem("selectedOptions");
//     localStorage.removeItem("selectedDate");
//     localStorage.removeItem("selectedDoctor");
//     localStorage.removeItem("options");
//     localStorage.removeItem("doctorsData");
//     localStorage.removeItem("optionsId");

//     navigate(`/patientLanding`);
//   };

//   const hanldeAddToCalender = async (patientId) => {
//     // if (!viewId) {
//     //   return;
//     // }
//     try {
//       const payload = {
//         appointmentId: viewId,
//         patientId: patientId,
//       };
//       const apiResponse = await postData(
//         "googlecalendar/createEventPatient",
//         payload
//       );

//       if (apiResponse.code === 1) {
//         Swal.fire({
//           title: "Success",
//           text: apiResponse.message,
//           icon: "success",
//         });
//         setIsButtonDisabled(true);
//       } else {
//         Swal.fire({
//           title: "Error",
//           text: apiResponse.message,
//           icon: "error",
//         });
//       }
//     } catch (error) {
//       console.error("Error :", error);
//       return null;
//     }
//   };
//   return (
//     <>
//       {loading ? (
//         <Loader2 />
//       ) : (
//         <section>
//           <div className="container mx-auto px-4 py-4">
//             <h1 className="font-fontPoppins text-3xl xss:text-xl sm:text-xl font-bold text-center mb-7">
//               {heading}
//             </h1>

//             <div className="w-3/5 xss:w-full sm:w-full mx-auto relative bg-gray-100 px-4 py-5 shadow-md rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
//               <div className="w-full">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Patient's Name
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {viewDetails &&
//                     viewDetails.patients &&
//                     viewDetails.patients.first_name}{" "}
//                   {viewDetails &&
//                     viewDetails.patients &&
//                     viewDetails.patients.last_name}
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Appointment
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {patientTypeId === 2 ? "Existing Patient" : "New Patient"} |{" "}
//                   {appoinmentName.services} <br />
//                   {formatAppointmentDate(viewDetails.appointment_date_time)}
//                 </p>
//               </div>
//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Appointment Duration
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {appoinmentName.duration} minutes.
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Address
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {Street + " " + address}
//                   <br />
//                   {Phone}
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Consulting Practitioner
//                 </h2>
//                 <div className="flex flex-col">
//                   <p className="font-fontPoppins text-sm font-semibold text-black">
//                     {viewDetails?.practitioners?.f_name}{" "}
//                     {viewDetails?.practitioners?.l_name}{" "}
//                     <span className="text-gray-700">
//                       ({viewDetails?.practitioners?.taxonomy_description.trim()}
//                       )
//                     </span>
//                   </p>
//                 </div>
//               </div>

//               {clinicinsuranceId === null ? (
//                 <div className="w-full mt-4">
//                   <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                     Insurance Type
//                   </h2>
//                   <p className="font-fontPoppins text-sm font-semibold text-black">
//                     {insurance}
//                   </p>
//                 </div>
//               ) : (
//                 <>
//                   <div className="w-full mt-4">
//                     <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                       Insurance Carrier
//                     </h2>
//                     <p className="font-fontPoppins text-sm font-semibold text-black">
//                       {carrierName}
//                     </p>
//                   </div>
//                   <div className="w-full mt-4">
//                     <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                       Insurance Plan
//                     </h2>
//                     <p className="font-fontPoppins text-sm font-semibold text-black">
//                       {insurancePlanName}
//                     </p>
//                   </div>
//                 </>
//               )}

//               <div className="w-full mt-4 bg-yellow-100 px-4 py-2 rounded-lg shadow-sm">
//                 <h4 className="font-fontPoppins text-sm font-semibold text-black-400">
//                   Note
//                 </h4>
//                 <p className="font-fontPoppins text-xs font-normal">
//                   Please arrive 15 minutes prior to your scheduled appointment
//                   time to complete any necessary paperwork. Remember to bring
//                   your insurance if applied/available card and a valid photo ID.
//                 </p>
//               </div>
//             </div>

//             <div className="w-3/5 xss:w-full sm:w-full mx-auto mt-4 xss:text-center md:flex md:justify-between">
//               <button
//                 disabled={isButtonDisabled}
//                 onClick={() => hanldeAddToCalender(patientId)}
//                 className={`h-12 font-fontPoppins text-sm rounded-full text-white font-semibold px-4 xss:mb-2 ${
//                   isButtonDisabled
//                     ? "bg-gray-400 cursor-not-allowed"
//                     : "bg-blue-500 hover:bg-blue-600"
//                 }`}
//               >
//                 <i className="fa-solid fa-calendar-plus fa-lg"></i> Add To
//                 Calendar
//               </button>

//               <button
//                 onClick={() =>
//                   navigate(`/clinicrescheduleappointment/${viewId}`)
//                 }
//                 className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
//               >
//                 <i className="fa-solid fa-rotate-right fa-lg"></i> Reschedule{" "}
//               </button>

//               <button
//                 onClick={handleBookAnotherAppointment}
//                 className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
//               >
//                 Book Another Appointment{" "}
//                 <i className="fa-solid fa-arrow-right-long fa-lg"></i>
//               </button>
//             </div>
//           </div>
//         </section>
//       )}
//     </>
//   );
// }

// export default ClinicBookedAppointmentDetails;

// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { postData } from "../../Networking/Api";
// import Header from "../Header";
// //import ThirdPartyHeader from "./ThirdPartyHeader";
// import Swal from "sweetalert2";
// //import ThirdPartyFooter from "./ThirdPartyFooter";
// import Loader from "../Loader";
// import ClinicBookingHeader from "./ClinicBookingHeader";
// import ClinicBookingFooter from "./ClinicBookingFooter";
// import Loader2 from "../Loader2";
// import { faL } from "@fortawesome/free-solid-svg-icons";
// import ClinicBookingHeader2 from "./ClinicBookingHeader2";
// import moment from "moment-timezone";

// function ClinicBookedAppointmentDetails() {
//   const { viewId } = useParams();
//   const pathlocation = useLocation();
//   const showFirstHeader =
//     pathlocation.pathname === "/bookappointmentDetails/:viewId";
//   const [logoImage, setLogoImage] = useState("");
//   const [locationDetails, setLocationDetails] = useState({});
//   const [Phone, setPhone] = useState("");
//   const [Street, setStreet] = useState("");
//   const [address, setAddress] = useState("");
//   const [clinicname, setClinicName] = useState("");
//   const [practionIdlocation, setPractionIdlocation] = useState();
//   const [loading, setLoading] = useState(true);

//   console.log(viewId);

//   useEffect(() => {
//     console.log("Initial Render, View ID:", viewId);
//     if (viewId) {
//       getRescheduleAppointmentData(viewId);
//     }
//   }, []);
//   const getRescheduleAppointmentData = async (viewId) => {
//     console.log("viewId", viewId);

//     // if (!viewId) {
//     //   return;
//     // }
//     try {
//       setLoading(true);

//       const payload = {
//         modelName: "patient_appointments",
//         whereCondition: {
//           id: viewId,
//         },
//         relations: [
//           {
//             module: "practitioners",
//             subModule: "clinic_locations",
//           },
//           // {
//           //   module: "appointment_type",
//           // },
//           {
//             module: "practitioner_calendars",
//           },
//           {
//             module: "clinics",
//           },
//         ],
//       };
//       const response = await postData("/masters/getMasterList", payload);
//       console.log("rescheduleres", response);
//       console.log(response.data[0].rescheduled_ref);

//       const logoUrl = response.data[0].clinics.logo_image;
//       setLogoImage(logoUrl);
//       setClinicName(response.data[0].clinics.name);
//       getPractitonerLocationData(response.data[0].clinic_location_id);
//       setPractionIdlocation(response.data[0].clinic_location_id);
//       console.log("data", response.data[0].clinic_location_id);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };

//   const getPractitonerLocationData = async (practionerLocationId) => {
//     if (practionerLocationId) {
//       try {
//         setLoading(true);
//         const payload = {
//           modelName: "clinic_locations",
//           whereCondition: {
//             id: practionerLocationId,
//           },
//         };
//         const response = await postData("/masters/getMasterList", payload);
//         const clinicData = response.data[0];
//         console.log("res", practionerLocationId, clinicData);
//         setStreet(clinicData.street);
//         setPhone(clinicData.phone);
//         const { street, city, state, country, zipcode } = clinicData;
//         const fullAddress = `${city}, ${state}, ${country}, ${zipcode}`;
//         setAddress(fullAddress);
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         console.error("Error fetching insurance options:", error);
//       }
//     }
//   };
//   return (
//     <>
//       <ClinicBookingHeader2
//         logoImage={logoImage}
//         Phone={Phone}
//         Street={Street}
//         address={address}
//         clinicname={clinicname}
//         // practionIdlocation={practionIdlocation}
//       />
//       <YourBookingDetails
//         loading={loading}
//         setLoading={setLoading}
//         address={address}
//         Phone={Phone}
//         Street={Street}
//       />

//       <ClinicBookingFooter />
//     </>
//   );
// }

// function YourBookingDetails({ loading, setLoading, address, Phone, Street }) {
//   const location = useLocation();
//   const { patientType, insuranceType, appointmentType, type, id, viewId } =
//     useParams();

//   const navigate = useNavigate();
//   const [viewDetails, setViewDetails] = useState("");
//   const [appoinmentName, setAppoinmentName] = useState("");
//   const [insuranceName, setInsuranceName] = useState("");
//   // const [address, setAddress] = useState("");
//   const [selectedAppointment, setSelectedAppointment] = useState([]);
//   const [patientTypeName, setPatientTypeName] = useState("");
//   const [patientId, setPatientId] = useState("");
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

//   const [iplocation, setIpLocation] = useState("");
//   const [timeZone, setTimeZone] = useState("");
//   const [locationName, setLocationName] = useState("");

//   const [patientTypeId, setPatientTypeId] = useState("");
//   const [clinicinsuranceId, setClinicInsuranceId] = useState("");
//   const [serviceId, setServiceId] = useState("");
//   const [carrierName, setCarrierName] = useState("");
//   const [insurancePlanName, setInsurancePlanName] = useState("");
//   const [patientAppointmentId, setPatientAppointmentId] = useState("");
//   const [status, setStatus] = useState("");
//   const [rescheduleref, setRescheduleref] = useState(null);
//   const [heading, setHeading] = useState("");

//   const [insurance, setInsurance] = useState("");
//   useEffect(() => {
//     getAppointmentDetails(viewId);
//   }, [viewId]);

//   useEffect(() => {
//     const getLocationFromIP = async () => {
//       try {
//         // setLoading(true);
//         var currentTimeZone = localStorage.getItem("currentTimeZone");
//         // alert(currentTimeZone)
//         if (
//           currentTimeZone == "null" ||
//           currentTimeZone == null ||
//           currentTimeZone == ""
//         ) {
//           // alert("if")
//           try {
//             // setLoading(true);
//             const response = await fetch("https://ipapi.co/json/");
//             if (!response.ok) {
//               // setLoading(false);
//               throw new Error("Network response was not ok");
//             }
//             const IpData = await response.json();

//             setIpLocation({
//               lat: IpData.latitude,
//               lon: IpData.longitude,
//             });

//             const locationName = `${IpData.city}, ${IpData.region_code}`;
//             setLocationName(locationName);

//             const timeZone = `${IpData.utc_offset}, ${IpData.timezone}`;
//             setTimeZone(timeZone);
//             // alert(timeZone)
//             localStorage.setItem("currentTimeZone", timeZone);
//           } catch (error) {
//             console.error("Error fetching data:", error);
//           }
//         } else {
//           // alert("else")
//           setTimeZone(JSON.parse(currentTimeZone));
//           // setLoading(false);
//         }
//       } catch (error) {
//         console.error("Error fetching IP location:", error);
//         // setLoading(false);
//       }
//     };
//     getLocationFromIP();
//   }, []);

//   const getAppointmentDetails = async (viewId) => {
//     console.log("viewId2", viewId);

//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "patient_appointments",
//         whereCondition: {
//           id: viewId,
//         },
//         relations: [
//           {
//             module: "clinics",
//           },
//           {
//             module: "patients",
//           },
//           {
//             module: "practitioners",
//             subModule: "clinic_locations",
//           },
//           // {
//           //   module: "appointment_type",
//           // },
//         ],
//       };

//       const apiResponse = await postData("masters/getMasterList", payload);

//       if (apiResponse.code == 1) {
//         setLoading(false);
//         setViewDetails(apiResponse.data[0]);
//         console.log("view", viewDetails);
//         setHeading(
//           determineHeading(
//             apiResponse.data[0].status,
//             apiResponse.data[0].rescheduled_ref
//           )
//         );
//         setPatientId(apiResponse.data[0].patient_id);
//         setPatientAppointmentId(apiResponse.data[0].id);
//         setPatientTypeId(apiResponse.data[0].patient_type_id);
//         setClinicInsuranceId(apiResponse.data[0].clinic_insurance_id);
//         setServiceId(apiResponse.data[0].clinic_service_id);
//         setInsurance(apiResponse.data[0].insurance);
//       } else {
//         setLoading(false);
//         return null;
//       }
//     } catch (error) {
//       console.error("Error searching provider:", error);
//       return null;
//     }
//   };
//   const determineHeading = (status, rescheduleref) => {
//     console.log(status, rescheduleref, "data");
//     if (status === "Booked") {
//       return !rescheduleref
//         ? "Your Appointment Is Booked!"
//         : "Your Appointment Is Rescheduled!";
//     } else if (status == "Cancelled") {
//       return "Reschedule Your Appointment!";
//     }
//     return "";
//   };
//   useEffect(() => {
//     if (clinicinsuranceId) {
//       getClinicInsuranceData(clinicinsuranceId);
//     }
//   }, [clinicinsuranceId]);
//   useEffect(() => {
//     if (serviceId) {
//       getClinicServiceData(serviceId);
//     }
//   }, [serviceId]);

//   const getClinicInsuranceData = async (clinicinsuranceId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_insurances",
//         whereCondition: {
//           id: clinicinsuranceId,
//         },
//         relations: [
//           {
//             module: "insurance_masters",
//           },
//           {
//             module: "health_insurance_carrier_master",
//           },
//         ],
//       };
//       const response = await postData("/masters/getMasterList", payload);

//       setCarrierName(
//         response.data[0].health_insurance_carrier_master
//           .health_insurance_carrier_name
//       );
//       setInsurancePlanName(response.data[0].insurance_masters.insurance_name);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };
//   const getClinicServiceData = async (serviceId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_services",
//         whereCondition: {
//           id: serviceId,
//         },
//       };
//       const response = await postData("/masters/getMasterList", payload);

//       setAppoinmentName(response.data[0]);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching insurance options:", error);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     const selectedAppointmentData = JSON.parse(
//       localStorage.getItem("selectedAppointment")
//     );
//     if (selectedAppointmentData) {
//       setSelectedAppointment(selectedAppointmentData);
//     }
//     setLoading(false);
//   }, []);

//   // useEffect(() => {
//   //   const getClinicDetails = async () => {
//   //     try {
//   //       setLoading(true);
//   //       const payload = {
//   //         modelName: "clinics",
//   //         whereCondition: {
//   //           id: viewDetails.clinic_id,
//   //         },
//   //       };
//   //       const response = await postData("masters/getMasterList", payload);

//   //       const clinicData = response.data[0];
//   //       const addressParts = [
//   //         clinicData.street,
//   //         clinicData.address,
//   //         clinicData.city,
//   //         clinicData.state,

//   //         clinicData.zipcode,
//   //       ];

//   //       const formattedAddress = addressParts.filter(Boolean).join(", ");
//   //       setAddress(formattedAddress);
//   //       setLoading(false);
//   //     } catch (error) {
//   //       setLoading(false);
//   //       console.error("Error fetching clinic details:", error);
//   //     }
//   //   };
//   //   getClinicDetails();
//   // }, [viewDetails]);

//   function formatAppointmentDate(dateString) {
//     console.log("dateString---->", dateString);
//     const currentTimeZone = localStorage.getItem("currentTimeZone");
//     var patientTimeZone = currentTimeZone.split(",")[1];
//     var timeZone = patientTimeZone.trim();
//     timeZone = "America/New_York";
//     const utcTimestamp = dateString;
//     const localTimestamp = moment
//       .utc(utcTimestamp)
//       .tz("America/New_York")
//       .format("ddd, DD MMM hh:mm A");
//     console.log("Retrieved local timestamp:", localTimestamp);
//     return localTimestamp;
//     const date = new Date(dateString);
//     const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//     const months = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];

//     const dayName = days[date.getUTCDay()];
//     const day = date.getUTCDate();
//     const monthName = months[date.getUTCMonth()];
//     const hours = date.getUTCHours();
//     const minutes = date.getUTCMinutes();

//     const formattedHours = hours % 12 || 12;
//     const formattedMinutes = minutes.toString().padStart(2, "0");
//     const ampm = hours >= 12 ? "PM" : "AM";

//     return `${dayName}, ${day} ${monthName} ${formattedHours}:${formattedMinutes}${ampm}`;
//   }
//   const handleBookAnotherAppointment = () => {
//     localStorage.removeItem("selectedSlot");
//     localStorage.removeItem("selectedOptions");
//     localStorage.removeItem("selectedDate");
//     localStorage.removeItem("selectedDoctor");
//     localStorage.removeItem("options");
//     localStorage.removeItem("doctorsData");
//     localStorage.removeItem("optionsId");

//     navigate(`/patientLanding`);
//   };

//   const hanldeAddToCalender = async (patientId) => {
//     // if (!viewId) {
//     //   return;
//     // }
//     try {
//       const payload = {
//         appointmentId: viewId,
//         patientId: patientId,
//       };
//       const apiResponse = await postData(
//         "googlecalendar/createEventPatient",
//         payload
//       );

//       if (apiResponse.code === 1) {
//         Swal.fire({
//           title: "Success",
//           text: apiResponse.message,
//           icon: "success",
//         });
//         setIsButtonDisabled(true);
//       } else {
//         Swal.fire({
//           title: "Error",
//           text: apiResponse.message,
//           icon: "error",
//         });
//       }
//     } catch (error) {
//       console.error("Error :", error);
//       return null;
//     }
//   };
//   return (
//     <>
//       {loading ? (
//         <Loader2 />
//       ) : (
//         <section>
//           <div className="container mx-auto px-4 py-4">
//             <h1 className="font-fontPoppins text-3xl xss:text-xl sm:text-xl font-bold text-center mb-7">
//               {heading}
//             </h1>

//             <div className="w-3/5 xss:w-full sm:w-full mx-auto relative bg-gray-100 px-4 py-5 shadow-md rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
//               <div className="w-full">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Patient's Name
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {viewDetails &&
//                     viewDetails.patients &&
//                     viewDetails.patients.first_name}{" "}
//                   {viewDetails &&
//                     viewDetails.patients &&
//                     viewDetails.patients.last_name}
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Appointment
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {patientTypeId === 2 ? "Existing Patient" : "New Patient"} |{" "}
//                   {appoinmentName.services} <br />
//                   {formatAppointmentDate(viewDetails.appointment_date_time)}
//                 </p>
//               </div>
//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Appointment Duration
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {appoinmentName.duration} minutes.
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Address
//                 </h2>
//                 <p className="font-fontPoppins text-sm font-semibold text-black">
//                   {Street + " " + address}
//                   <br />
//                   {Phone}
//                 </p>
//               </div>

//               <div className="w-full mt-4">
//                 <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                   Consulting Practitioner
//                 </h2>
//                 <div className="flex flex-col">
//                   <p className="font-fontPoppins text-sm font-semibold text-black">
//                     {viewDetails?.practitioners?.f_name}{" "}
//                     {viewDetails?.practitioners?.l_name}{" "}
//                     <span className="text-gray-700">
//                       ({viewDetails?.practitioners?.taxonomy_description.trim()}
//                       )
//                     </span>
//                   </p>
//                 </div>
//               </div>

//               {clinicinsuranceId === null ? (
//                 <div className="w-full mt-4">
//                   <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                     Insurance Type
//                   </h2>
//                   <p className="font-fontPoppins text-sm font-semibold text-black">
//                     {insurance}
//                   </p>
//                 </div>
//               ) : (
//                 <>
//                   <div className="w-full mt-4">
//                     <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                       Insurance Carrier
//                     </h2>
//                     <p className="font-fontPoppins text-sm font-semibold text-black">
//                       {carrierName}
//                     </p>
//                   </div>
//                   <div className="w-full mt-4">
//                     <h2 className="font-fontPoppins text-sm font-medium text-black-100">
//                       Insurance Plan
//                     </h2>
//                     <p className="font-fontPoppins text-sm font-semibold text-black">
//                       {insurancePlanName}
//                     </p>
//                   </div>
//                 </>
//               )}

//               <div className="w-full mt-4 bg-yellow-100 px-4 py-2 rounded-lg shadow-sm">
//                 <h4 className="font-fontPoppins text-sm font-semibold text-black-400">
//                   Note
//                 </h4>
//                 <p className="font-fontPoppins text-xs font-normal">
//                   Please arrive 15 minutes prior to your scheduled appointment
//                   time to complete any necessary paperwork. Remember to bring
//                   your insurance if applied/available card and a valid photo ID.
//                 </p>
//               </div>
//             </div>

//             <div className="w-3/5 xss:w-full sm:w-full mx-auto mt-4 xss:text-center md:flex md:justify-between">
//               <button
//                 disabled={isButtonDisabled}
//                 onClick={() => hanldeAddToCalender(patientId)}
//                 className={`h-12 font-fontPoppins text-sm rounded-full text-white font-semibold px-4 xss:mb-2 ${
//                   isButtonDisabled
//                     ? "bg-gray-400 cursor-not-allowed"
//                     : "bg-blue-500 hover:bg-blue-600"
//                 }`}
//               >
//                 <i className="fa-solid fa-calendar-plus fa-lg"></i> Add To
//                 Calendar
//               </button>

//               <button
//                 onClick={() =>
//                   navigate(`/clinicrescheduleappointment/${viewId}`)
//                 }
//                 className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
//               >
//                 <i className="fa-solid fa-rotate-right fa-lg"></i> Reschedule{" "}
//               </button>

//               <button
//                 onClick={handleBookAnotherAppointment}
//                 className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
//               >
//                 Book Another Appointment{" "}
//                 <i className="fa-solid fa-arrow-right-long fa-lg"></i>
//               </button>
//             </div>
//           </div>
//         </section>
//       )}
//     </>
//   );
// }

// export default ClinicBookedAppointmentDetails;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData } from "../../Networking/Api";
import Header from "../Header";
//import ThirdPartyHeader from "./ThirdPartyHeader";
import Swal from "sweetalert2";
//import ThirdPartyFooter from "./ThirdPartyFooter";
import Loader from "../Loader";
import ClinicBookingHeader from "./ClinicBookingHeader";
import ClinicBookingFooter from "./ClinicBookingFooter";
import Loader2 from "../Loader2";
import { faL } from "@fortawesome/free-solid-svg-icons";
import ClinicBookingHeader2 from "./ClinicBookingHeader2";
import moment from "moment-timezone";

function ClinicBookedAppointmentDetails() {
  const { viewId } = useParams();
  const pathlocation = useLocation();
  const showFirstHeader =
    pathlocation.pathname === "/bookappointmentDetails/:viewId";
  const [logoImage, setLogoImage] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const [Phone, setPhone] = useState("");
  const [Street, setStreet] = useState("");
  const [address, setAddress] = useState("");
  const [clinicname, setClinicName] = useState("");
  const [practionIdlocation, setPractionIdlocation] = useState();
  const [loading, setLoading] = useState(true);
  const [patientId, setPatientId] = useState("");
  const [patientAppointmentId, setPatientAppointmentId] = useState("");
  const [insurance, setInsurance] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [patientTypeId, setPatientTypeId] = useState("");
  const [clinicinsuranceId, setClinicInsuranceId] = useState("");
  const [viewDetails, setViewDetails] = useState("");
  const [heading, setHeading] = useState("");
  const [timeZoneData, setTimeZoneData] = useState("");

  console.log(viewId);

  useEffect(() => {
    console.log("Initial Render, View ID:", viewId);
    if (viewId) {
      getRescheduleAppointmentData(viewId);
    }
  }, []);
  const getRescheduleAppointmentData = async (viewId) => {
    console.log("viewId", viewId);

    // if (!viewId) {
    //   return;
    // }
    try {
      setLoading(true);

      const payload = {
        modelName: "patient_appointments",
        whereCondition: {
          id: viewId,
        },
        relations: [
          {
            module: "practitioners",
            subModule: "clinic_locations",
          },
          // {
          //   module: "appointment_type",
          // },
          {
            module: "practitioner_calendars",
          },
          {
            module: "patients",
            // moduleas
          },
          {
            module: "clinics",
          },
        ],
      };
      const response = await postData("/masters/getMasterList", payload);
      console.log("rescheduleres", response);
      console.log(response.data[0].rescheduled_ref);

      const logoUrl = response.data[0].clinics.logo_image;
      setLogoImage(logoUrl);
      setClinicName(response.data[0].clinics.name);
      getPractitonerLocationData(response.data[0].clinic_location_id);
      setPractionIdlocation(response.data[0].clinic_location_id);
      setPatientId(response.data[0].patient_id);
      setPatientAppointmentId(response.data[0].id);
      setPatientTypeId(response.data[0].patient_type_id);
      setClinicInsuranceId(response.data[0].clinic_insurance_id);
      setServiceId(response.data[0].clinic_service_id);
      setInsurance(response.data[0].insurance);
      setViewDetails(response.data[0]);
      setHeading(
        determineHeading(
          response.data[0].status,
          response.data[0].rescheduled_ref
        )
      );
      console.log("heading", heading);

      console.log("data", response.data[0].clinic_location_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching insurance options:", error);
    }
  };

  const getPractitonerLocationData = async (practionerLocationId) => {
    if (practionerLocationId) {
      try {
        setLoading(true);
        const payload = {
          modelName: "clinic_locations",
          whereCondition: {
            id: practionerLocationId,
          },
        };
        const response = await postData("/masters/getMasterList", payload);
        const clinicData = response.data[0];
        console.log("res", practionerLocationId, clinicData);
        setTimeZoneData(clinicData.time_zone);
        setStreet(clinicData.street);
        setPhone(clinicData.phone);
        const { street, city, state, country, zipcode } = clinicData;
        const fullAddress = `${city}, ${state}, ${country}, ${zipcode}`;
        setAddress(fullAddress);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching insurance options:", error);
      }
    }
  };
  const determineHeading = (status, rescheduleref) => {
    console.log(status, rescheduleref, "data");
    if (status === "Booked") {
      return !rescheduleref
        ? "Your Appointment Is Booked!"
        : "Your Appointment Is Rescheduled!";
    } else if (status == "Cancelled") {
      return "Reschedule Your Appointment!";
    }
    return "";
  };
  return (
    <>
      <ClinicBookingHeader2
        logoImage={logoImage}
        Phone={Phone}
        Street={Street}
        address={address}
        clinicname={clinicname}

        // practionIdlocation={practionIdlocation}
      />
      <YourBookingDetails
        loading={loading}
        setLoading={setLoading}
        address={address}
        Phone={Phone}
        Street={Street}
        clinicinsuranceId={clinicinsuranceId}
        patientTypeId={patientTypeId}
        serviceId={serviceId}
        insurance={insurance}
        patientAppointmentId={patientAppointmentId}
        patientId={patientId}
        viewDetails={viewDetails}
        heading={heading}
        timeZoneData={timeZoneData}
      />

      <ClinicBookingFooter />
    </>
  );
}

function YourBookingDetails({
  loading,
  setLoading,
  address,
  Phone,
  Street,
  patientAppointmentId,
  patientId,
  clinicinsuranceId,
  patientTypeId,
  serviceId,
  insurance,
  viewDetails,
  heading,
  timeZoneData,
}) {
  const location = useLocation();
  const { patientType, insuranceType, appointmentType, type, id, viewId } =
    useParams();

  const navigate = useNavigate();

  const [appoinmentName, setAppoinmentName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  // const [address, setAddress] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState([]);
  const [patientTypeName, setPatientTypeName] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [iplocation, setIpLocation] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [locationName, setLocationName] = useState("");

  const [carrierName, setCarrierName] = useState("");
  const [insurancePlanName, setInsurancePlanName] = useState("");

  const [status, setStatus] = useState("");
  // const [rescheduleref, setRescheduleref] = useState(null);
  // const [heading, setHeading] = useState("");

  // useEffect(() => {
  //   getAppointmentDetails(viewId);
  // }, [viewId]);

  useEffect(() => {
    const getLocationFromIP = async () => {
      try {
        // setLoading(true);
        var currentTimeZone = localStorage.getItem("currentTimeZone");
        // alert(currentTimeZone)
        if (
          currentTimeZone == "null" ||
          currentTimeZone == null ||
          currentTimeZone == ""
        ) {
          // alert("if")
          try {
            // setLoading(true);
            const response = await fetch("https://ipapi.co/json/");
            if (!response.ok) {
              // setLoading(false);
              throw new Error("Network response was not ok");
            }
            const IpData = await response.json();

            setIpLocation({
              lat: IpData.latitude,
              lon: IpData.longitude,
            });

            const locationName = `${IpData.city}, ${IpData.region_code}`;
            setLocationName(locationName);

            const timeZone = `${IpData.utc_offset}, ${IpData.timezone}`;
            setTimeZone(timeZone);
            // alert(timeZone)
            localStorage.setItem("currentTimeZone", timeZone);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          // alert("else")
          setTimeZone(JSON.parse(currentTimeZone));
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching IP location:", error);
        // setLoading(false);
      }
    };
    getLocationFromIP();
  }, []);

  // const getAppointmentDetails = async (viewId) => {
  //   console.log("viewId2", viewId);

  //   try {
  //     setLoading(true);
  //     const payload = {
  //       modelName: "patient_appointments",
  //       whereCondition: {
  //         id: viewId,
  //       },
  //       relations: [
  //         {
  //           module: "clinics",
  //         },
  //         {
  //           module: "patients",
  //         },
  //         {
  //           module: "practitioners",
  //           subModule: "clinic_locations",
  //         },
  //         // {
  //         //   module: "appointment_type",
  //         // },
  //       ],
  //     };

  //     const apiResponse = await postData("masters/getMasterList", payload);

  //     if (apiResponse.code == 1) {
  //       setLoading(false);
  //       setViewDetails(apiResponse.data[0]);
  //       console.log("view", viewDetails);
  //       setHeading(
  //         determineHeading(
  //           apiResponse.data[0].status,
  //           apiResponse.data[0].rescheduled_ref
  //         )
  //       );
  //       setPatientId(apiResponse.data[0].patient_id);
  //       setPatientAppointmentId(apiResponse.data[0].id);
  //       setPatientTypeId(apiResponse.data[0].patient_type_id);
  //       setClinicInsuranceId(apiResponse.data[0].clinic_insurance_id);
  //       setServiceId(apiResponse.data[0].clinic_service_id);
  //       setInsurance(apiResponse.data[0].insurance);
  //     } else {
  //       setLoading(false);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error searching provider:", error);
  //     return null;
  //   }
  // };

  useEffect(() => {
    if (clinicinsuranceId) {
      getClinicInsuranceData(clinicinsuranceId);
    }
  }, [clinicinsuranceId]);
  useEffect(() => {
    if (serviceId) {
      getClinicServiceData(serviceId);
    }
  }, [serviceId]);

  const getClinicInsuranceData = async (clinicinsuranceId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_insurances",
        whereCondition: {
          id: clinicinsuranceId,
        },
        relations: [
          {
            module: "insurance_masters",
          },
          {
            module: "health_insurance_carrier_master",
          },
        ],
      };
      const response = await postData("/masters/getMasterList", payload);

      setCarrierName(
        response.data[0].health_insurance_carrier_master
          .health_insurance_carrier_name
      );
      setInsurancePlanName(response.data[0].insurance_masters.insurance_name);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching insurance options:", error);
    }
  };
  const getClinicServiceData = async (serviceId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          id: serviceId,
        },
      };
      const response = await postData("/masters/getMasterList", payload);

      setAppoinmentName(response.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching insurance options:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const selectedAppointmentData = JSON.parse(
      localStorage.getItem("selectedAppointment")
    );
    if (selectedAppointmentData) {
      setSelectedAppointment(selectedAppointmentData);
    }
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   const getClinicDetails = async () => {
  //     try {
  //       setLoading(true);
  //       const payload = {
  //         modelName: "clinics",
  //         whereCondition: {
  //           id: viewDetails.clinic_id,
  //         },
  //       };
  //       const response = await postData("masters/getMasterList", payload);

  //       const clinicData = response.data[0];
  //       const addressParts = [
  //         clinicData.street,
  //         clinicData.address,
  //         clinicData.city,
  //         clinicData.state,

  //         clinicData.zipcode,
  //       ];

  //       const formattedAddress = addressParts.filter(Boolean).join(", ");
  //       setAddress(formattedAddress);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching clinic details:", error);
  //     }
  //   };
  //   getClinicDetails();
  // }, [viewDetails]);

  function formatAppointmentDate(dateString, timeZoneData) {
    console.log("dateString---->", dateString, timeZoneData);
    const currentTimeZone = localStorage.getItem("currentTimeZone");
    var patientTimeZone = currentTimeZone.split(",")[1];
    var timeZone = patientTimeZone.trim();
    timeZone = "America/New_York";
    const utcTimestamp = dateString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz(timeZoneData || "America/New_York")
      .format("ddd, DD MMM hh:mm A");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayName = days[date.getUTCDay()];
    const day = date.getUTCDate();
    const monthName = months[date.getUTCMonth()];
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${dayName}, ${day} ${monthName} ${formattedHours}:${formattedMinutes}${ampm}`;
  }
  const handleBookAnotherAppointment = () => {
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("selectedDoctor");
    localStorage.removeItem("options");
    localStorage.removeItem("doctorsData");
    localStorage.removeItem("optionsId");

    navigate(`/patientLanding`);
  };

  const hanldeAddToCalender = async (patientId) => {
    // if (!viewId) {
    //   return;
    // }
    try {
      const payload = {
        appointmentId: viewId,
        patientId: patientId,
      };
      const apiResponse = await postData(
        "googlecalendar/createEventPatient",
        payload
      );

      if (apiResponse.code === 1) {
        Swal.fire({
          title: "Success",
          text: apiResponse.message,
          icon: "success",
        });
        setIsButtonDisabled(true);
      } else {
        Swal.fire({
          title: "Error",
          text: apiResponse.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error :", error);
      return null;
    }
  };
  return (
    <>
      {loading ? (
        <Loader2 />
      ) : (
        <section>
          <div className="container mx-auto px-4 py-4">
            <h1 className="font-fontPoppins text-3xl xss:text-xl sm:text-xl font-bold text-center mb-7">
              {heading}
            </h1>

            <div className="xss:w-full sm:w-full mx-auto relative bg-gray-100 px-4 py-5 shadow-md rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
              <div className="w-full">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Patient's Name
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {viewDetails &&
                    viewDetails.patients &&
                    viewDetails.patients.first_name}{" "}
                  {viewDetails &&
                    viewDetails.patients &&
                    viewDetails.patients.last_name}
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Appointment
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {patientTypeId === 2 ? "Existing Patient" : "New Patient"} |{" "}
                  {appoinmentName.services} <br />
                  {formatAppointmentDate(
                    viewDetails.appointment_date_time,
                    timeZoneData
                  )}
                </p>
              </div>
              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Appointment Duration
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {appoinmentName.duration} minutes.
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Address
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {Street + " " + address}
                  <br />
                  {Phone}
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Consulting Practitioner
                </h2>
                <div className="flex flex-col">
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {viewDetails?.practitioners?.f_name}{" "}
                    {viewDetails?.practitioners?.l_name}{" "}
                    <span className="text-gray-700">
                      ({viewDetails?.practitioners?.taxonomy_description.trim()}
                      )
                    </span>
                  </p>
                </div>
              </div>

              {clinicinsuranceId === null ? (
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                    Insurance Type
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {insurance}
                  </p>
                </div>
              ) : (
                <>
                  <div className="w-full mt-4">
                    <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                      Insurance Carrier
                    </h2>
                    <p className="font-fontPoppins text-sm font-semibold text-black">
                      {carrierName}
                    </p>
                  </div>
                  <div className="w-full mt-4">
                    <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                      Insurance Plan
                    </h2>
                    <p className="font-fontPoppins text-sm font-semibold text-black">
                      {insurancePlanName}
                    </p>
                  </div>
                </>
              )}

              <div className="w-full mt-4 bg-yellow-100 px-4 py-2 rounded-lg shadow-sm">
                <h4 className="font-fontPoppins text-sm font-semibold text-black-400">
                  Note
                </h4>
                <p className="font-fontPoppins text-xs font-normal">
                  Please arrive 15 minutes prior to your scheduled appointment
                  time to complete any necessary paperwork. Remember to bring
                  your insurance if applied/available card and a valid photo ID.
                </p>
              </div>
            </div>

            <div className="xss:w-full sm:w-full mx-auto mt-4 xss:text-center md:flex md:justify-between">
              {/* <button
                disabled={isButtonDisabled}
                onClick={() => hanldeAddToCalender(patientId)}
                className={`h-12 font-fontPoppins text-sm rounded-full text-white font-semibold px-4 xss:mb-2 ${
                  isButtonDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                <i className="fa-solid fa-calendar-plus fa-lg"></i> Add To
                Calendar
              </button> */}

              <button
                onClick={() =>
                  navigate(`/clinicrescheduleappointment/${viewId}`)
                }
                className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
              >
                <i className="fa-solid fa-rotate-right fa-lg"></i> Reschedule{" "}
              </button>

              <button
                onClick={handleBookAnotherAppointment}
                className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
              >
                Book Another Appointment{" "}
                <i className="fa-solid fa-arrow-right-long fa-lg"></i>
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ClinicBookedAppointmentDetails;
