import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { postData } from "../Networking/Api";

function Password() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const validatePassword = () => {
    const errors = {};
    if (!oldPassword) errors.oldPassword = "Old password is required.";
    if (!newPassword) errors.newPassword = "New password is required.";
    if (!confirmPassword) errors.confirmPassword = "Confirm password is required.";

  
    if (newPassword.length < 6)
      errors.newPassword = "New password must be at least 6 characters long.";
    if (newPassword !== confirmPassword)
      errors.confirmPassword = "Passwords do not match.";

    setErrors(errors);

    // If there are no errors, validation passes
    return Object.keys(errors).length === 0;
  };

  const handleOldPasswordChange = async(e) => {
    // setOldPassword(e.target.value);
    const oldPasswordInput = e.target.value;
    setOldPassword(oldPasswordInput);

    

    
    }


  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    if (!validatePassword()) {
      return; 
    }

    
    try {
      const payload = {
        id: userId,
        newPassword,
        confirmPassword,
      };

      const response = await postData("auth/changePassword", payload);

      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "Password changed successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message || "Password change failed. Please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire("Error", "Password change failed. Please try again.", "error");
    }
  };

  return (
    <section className="w-full">
      <div
        className="w-full h-dvh bg-red-300 bg-no-repeat bg-left bg-cover bg-scroll md:flex md:items-center md:gap-4 px-4 py-4"
        style={{ backgroundImage: "url(./login-bg.jpg)" }}
      >
        <div className="w-2/5 xss:w-full"></div>
        <div className="w-3/5 xss:w-full min-h-80 bg-gray-100 rounded-lg shadow-lg px-4 py-6 xss:mt-20">
          <div className="flex flex-direction-row justify-center gap-3">
            <Link to="/">
              <img
                src={"./samara-logo.png"}
                width="300px"
                className="max-w-full"
              />
            </Link>
          </div>
          <div className="w-full mt-4">
            <h1 className="font-fontPoppins text-lg text-left">
              Change Password
            </h1>

            <form onSubmit={handleSubmit}>
              <div className="w-full mt-4">
                <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                  Old Password
                </label>
                <input
                  className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  placeholder="Enter Your Old Password"
                  onChange={handleOldPasswordChange}
                />
                {errors.oldPassword && (
                  <p className="text-red-500 text-sm">{errors.oldPassword}</p>
                )}
              </div>

              <div className="w-full mt-4">
                <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                  New Password
                </label>
                <input
                  className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  placeholder="Enter New Password"
                  onChange={handleNewPasswordChange}
                />
                {errors.newPassword && (
                  <p className="text-red-500 text-sm">{errors.newPassword}</p>
                )}
              </div>

              <div className="w-full mt-4">
                <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                  Confirm Password
                </label>
                <input
                  className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Retype New Password"
                  onChange={handleConfirmPasswordChange}
                />
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>

              <div className="w-full text-center mt-4">
                <button
                  type="submit"
                  className="w-40 py-2 bg-blueColor rounded-full font-fontPoppins text-sm font-medium text-white hover:bg-opacity-80"
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Password;
