import { useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  loginPatient,
  logoutPatient,
  logoutProvider,
  selectPatient,
  selectProvider,
} from "../redux/actions/userSlice";
import { useEffect } from "react";
import {
  GoogleLogin,
  googleLogout,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { apiUrl, postData } from "../Networking/Api";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { logEvent } from "../ga";

const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

const paddingNav = {
  padding: "16px",
};

const ActionTypes = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SHOW:
      return {
        ...state,
        [action.itemType]: { ...state[action.itemType], isVisible: true },
      };

    case ActionTypes.HIDE:
      return {
        ...state,
        [action.itemType]: { ...state[action.itemType], isVisible: false },
      };
    default:
      return state;
  }
};

function PatientsLandingPageHeader() {
  const [state, dispatch] = useReducer(reducer, {
    type1: { isVisible: false },
    type2: { isVisible: false },
    type3: { isVisible: false },
    // type4: { isVisible: false },
  });

  // const GOOGLE_MAPS_API_KEY = "YOUR_GOOGLE_MAPS_API_KEY";
  // const [location, setLocation] = useState(''); //for location searh
  // const [autocomplete, setAutocomplete] = useState(null); //for location searh

  //Only use for Show location Name
  const [location, setLocation] = useState({ lat: null, lon: null }); ///loc name
  const [time, setTime] = useState(new Date().toLocaleTimeString()); ///loc name
  const [locationName, setLocationName] = useState(""); ///loc name
  const [timeZone, setTimeZone] = useState("");
   const [MySubscriptionsLink, setMySubscriptionsLink] = useState(false);
  //show location based on IP address

  useEffect(() => {
    getLocationFromIP();

    // Update the time every second
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getLocationFromIP = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      // console.log("iplocation", data);
      const newLocation = {
        lat: data.latitude,
        lon: data.longitude,
      };
      setLocation(newLocation);
      localStorage.setItem("location", JSON.stringify(newLocation));
      setLocationName(`${data.city}, ${data.region_code}`);
      setTimeZone(`${data.utc_offset}, ${data.timezone}`);
    } catch (error) {
      console.error("Error fetching IP location:", error);
    }
  };
  // console.log("timezone..",timeZone);

  const [user, setUser] = useState();
  const [providerUser, setProviderUser] = useState();
  const [accountType, setAccountType] = useState(null);
  const [userName, setUsername] = useState();
  const [ProfilePic, setProfilePic] = useState();
  //const userData = useSelector(selectUser);
  //console.log("User..", userData);
  const patientData = useSelector(selectPatient);
  const providerData = useSelector(selectProvider);
  // console.log("Patient User:", patientData);
  // console.log("Provider User:", providerData);
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const pathlocation = useLocation();
  const showExtraElements = pathlocation.pathname === "/patientLanding";
  const hideElements =
    pathlocation.pathname === "/getpricing" || "/Services/:serviceId/:tab";
  const hideProviderElement = pathlocation.pathname === "/";
  // useEffect(() => {
  //   setUser(userData.isLoggedIn);
  // }, [userData]);
  useEffect(() => {
    const Username = localStorage.getItem("userName");
    setUsername(Username);
    const ProfilePic = localStorage.getItem("profilePic");
    setProfilePic(ProfilePic);
    const storedAccountType = localStorage.getItem("accountType");
    console.log("accountType....", storedAccountType);
    if (storedAccountType !== null) {
      setAccountType(storedAccountType);
    }
     if (storedAccountType == "Clinic") {
       setMySubscriptionsLink(true);
     }

    // console.log("storedAccountTYpe", storedAccountType);
  }, []);
  const getAccountTypeHeading = () => {
    const storedAccountType = localStorage.getItem("accountType");
    if (storedAccountType === "Clinic") return "Organization";
    if (storedAccountType === "Practitioners") return "Practitioner";
    if (storedAccountType === "Assistances") return "Assistance";
    return "Provider"; // Default to "Provider" if no accountType matches
  };

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  useEffect(() => {
    setProviderUser(providerData);
  }, [providerData]);
  // const handleLogout = () => {
  //   //localStorage.clear();
  //   Dispatch(logout());
  //   navigate("/");
  // };
  // const patientLogout=()=>{  //patient Logout -commented
  //    googleLogout();
  //    Dispatch(logout());
  //    //localStorage.clear();
  // }
  // const LoggedIn = user ? true : false;
  // const isDisabled = pathlocation.pathname == "/" || LoggedIn;
  // const isLoggedIn = () => {
  //   return !!localStorage.getItem("userInfo");
  // };

  // // Function to set the disabled state based on path and logged-in status
  // const getIsDisabled = (pathname) => {
  //   return !(pathname === "/patientLanding" || isLoggedIn());
  // };

  // // Get the current path
  // const pathLocation = window.location;

  // // Set the isDisabled value
  // const isDisabled = getIsDisabled(pathLocation.pathname);
  const LoggedIn = () => {
    const userInfo = localStorage.getItem("patientLog");
    return userInfo ? JSON.parse(userInfo).isLoggedIn : false;
  };
  // Function to set the disabled state based on path and logged-in status
  const getIsDisabled = (pathname) => {
    return pathname != "/patientLanding" || LoggedIn();
  };

  // Get the current path
  const pathLocation = window.location;

  // Set the isDisabled value
  const isDisabled = getIsDisabled(pathLocation.pathname);
  // console.log(`One-Tap Sign-In Disabled: ${isDisabled}`);
  // console.log(`One-Tap Sign-In Disabled: ${isDisabled}`);
  useGoogleOneTapLogin({
    onSuccess: async ({ credential }) => {
      try {
        const decoded = jwtDecode(credential);
        const email = decoded.email;
        const l_name = decoded.family_name;
        const f_name = decoded.given_name;

        const checkEmailExists = async (email) => {
          const payload = {
            modelName: "patients",
            whereCondition: { email: email },
          };

          try {
            const apiResponse = await postData(
              "masters/getMasterList",
              payload
            );
            if (apiResponse.code == 1) {
              localStorage.setItem(
                "userCreateInfo",
                JSON.stringify(apiResponse.data[0])
              );
              localStorage.setItem("accountType", "Patient");
              const UserName =
                apiResponse.data[0].first_name +
                " " +
                apiResponse.data[0].last_name;
              //apiResponse.data.first_name + " " + apiResponse.data.last_name;
              localStorage.setItem("userName", UserName);

              Dispatch(loginPatient({ isLoggedIn: true }));

              navigate("/patientLanding");
              logEvent("User", "Login", email);
              window.location.reload();
              return true;
            }
          } catch (error) {
            console.error("Error checking email existence:", error);
            return false;
          }
        };

        const updatePatientGoogleData = async () => {
          try {
            const userCreateInfo = JSON.parse(
              localStorage.getItem("userCreateInfo")
            );
            const userInfo = localStorage.getItem("userInfo");
            const payload1 = {
              modelName: "patients",
              id: userCreateInfo.id,
              inputData: {
                google_api_response: userInfo,
              },
            };
            await postData("masters/createAndUpdateMaster", payload1);
          } catch (error) {
            console.error("Error updating patient Google data:", error);
          }
        };

        const emailExists = await checkEmailExists(email);

        if (emailExists) {
          decoded.access_token = credential;
          localStorage.setItem("userInfo", JSON.stringify(decoded));
          await updatePatientGoogleData();
        } else {
          const createPayload = {
            modelName: "patients",
            inputData: {
              email: email,
              first_name: f_name,
              last_name: l_name,
            },
          };

          try {
            const createResponse = await postData(
              "masters/createAndUpdateMaster",
              createPayload
            );
            localStorage.setItem(
              "userCreateInfo",
              JSON.stringify(createResponse.data[0])
            );
            const UserName =
              createResponse.data[0].first_name +
              " " +
              createResponse.data[0].last_name;
            // createResponse.data.first_name +
            // " " +
            // createResponse.data.last_name;
            localStorage.setItem("userName", UserName);
            localStorage.setItem("accountType", "Patient");

            Dispatch(loginPatient({ isLoggedIn: true }));

            navigate("/patientLanding");
            logEvent("User", "Login", email);
            window.location.reload();
          } catch (error) {
            console.error("Error creating new record:", error);
          }
        }
      } catch (error) {
        console.error(
          "Error decoding credential or fetching user info:",
          error
        );
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
    disabled: isDisabled,
  });

  const handlePatientLogout = () => {
    localStorage.clear();
    googleLogout();
    Dispatch(logoutPatient());
    navigate("/");
    window.location.reload();
  };

  const handleProviderLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    Dispatch(logoutProvider());
    //navigate("/");
    navigate("/", { replace: true });
    window.location.reload();
  };

  const Login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        // Get user info from Google
        // console.log("access_token--->", access_token);
        localStorage.setItem("google_access_token", access_token);
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        // console.log("userInfoResponse--->", userInfoResponse);
        var userInfo = userInfoResponse.data;
        const email = userInfo.email;
        const l_name = userInfo.family_name;
        const f_name = userInfo.given_name;

        // Function to check if email exists in the database
        const checkEmailExists = async (email) => {
          const payload = {
            modelName: "patients",
            whereCondition: { email: email },
          };

          try {
            const apiResponse = await postData(
              "masters/getMasterList",
              payload
            );

            // console.log("API Response for email check:", apiResponse.data); // Log the response
            if (apiResponse.code == 1) {
              localStorage.setItem(
                "userCreateInfo",
                JSON.stringify(apiResponse.data[0])
              );
              localStorage.setItem("accountType", "Patient");
              const UserName =
                apiResponse.data[0].first_name +
                " " +
                apiResponse.data[0].last_name;
              //apiResponse.data.first_name + " " + apiResponse.data.last_name;
              localStorage.setItem("userName", UserName);

              Dispatch(
                loginPatient({
                  // user : payload,
                  isLoggedIn: true,
                })
              );
              logEvent("User", "Login", email);
              navigate("/patientLanding");
              return true;
            } // Check if the code is 1 meaning email exists
            else {
              return false;
            }
          } catch (error) {
            console.error("Error checking email existence:", error);
            return false; // In case of an error, treat it as if the email doesn't exist
          }
        };
        //////////////////added by rohit k end////////////
        const updtePatientGoogleData = async () => {
          try {
            const userCreateInfo = JSON.parse(
              localStorage.getItem("userCreateInfo")
            );
            const userInfo = localStorage.getItem("userInfo");
            console.log("userCreateInfo--->", userCreateInfo);
            console.log("userCreateInfo[0].id--->", userCreateInfo?.id);
            const payload1 = {
              modelName: "patients",
              id: userCreateInfo?.id,
              inputData: {
                google_api_response: userInfo,
              },
            };
            await postData("masters/createAndUpdateMaster", payload1);
          } catch (error) {
            console.error("Error checking email existence:", error);
            return false; // In case of an error, treat it as if the email doesn't exist
          }
        };
        //////////////////added by rohit k start ////////////
        // Check if email exists
        const emailExists = await checkEmailExists(email);

        // console.log("Does email exist?", emailExists);

        if (emailExists) {
          userInfo.access_token = access_token;
          // Email exists, store response in local storage
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          const updtePatientGoogleDataResp = await updtePatientGoogleData();
        } else {
          // Email does not exist, create a new record
          const createPayload = {
            modelName: "patients",
            inputData: {
              email: email,
              first_name: f_name,
              last_name: l_name,
            },
          };

          try {
            const createResponse = await postData(
              "masters/createAndUpdateMaster",
              createPayload
            );
            localStorage.setItem(
              "userCreateInfo",
              JSON.stringify(createResponse.data[0])
            );
            const UserName =
              createResponse.data[0].first_name +
                " " +
                createResponse.data[0].last_name ||
              // createResponse.data.first_name +
              // " " +
              // createResponse.data.last_name;

              localStorage.setItem("userName", UserName);
            localStorage.setItem("accountType", "Patient");
            Dispatch(
              loginPatient({
                // user : payload,
                isLoggedIn: true,
              })
            );

            navigate("/patientLanding");
            logEvent("User", "Login", email);

            // console.log("New record created:", createResponse.data);
          } catch (error) {
            console.error("Error creating new record:", error);
          }
        }
        if (window.location.pathname === "/patientLanding") {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
  });

  // const login = useGoogleOneTapLogin({
  //   onSuccess: (credentialResponse) => {
  //     console.log(credentialResponse);
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });
  //Google Serach places
  // const handlePlaceChanged = () => {
  //   if (autocomplete !== null) {
  //     const place = autocomplete.getPlace();
  //     setLocation(place.formatted_address);
  //   } else {
  //     console.log('Autocomplete is not loaded yet!');
  //   }
  // };
  return (
    <header className="w-full">
      <div
        // className="mx-auto sm:flex md:flex flex px-4 bg-white shadow-sm"
        className="flex justify-between gap-2"
        style={shadowBottom}
      >
        <div className="flex justify-center ml-2 mr-2 gap-4 items-center">
          <span
            className="cursor-pointer"
            onClick={() =>
              dispatch({ type: ActionTypes.SHOW, itemType: "type2" })
            }
          >
            <i class="fa-solid fa-bars fa-lg"></i>
          </span>
          <span>
            <Link to={user ? "/patientLanding" : "/"}>
              <img src={"/samara-logo.png"} alt="logo" width="133px" />
            </Link>
          </span>
          {!showExtraElements && (
            <>
              <span className="font-fontPoppins text-xs font-normal hidden sm:inline">
                Care, at the speed of thought!
              </span>
              {/* <button
                onClick={() => navigate("/scheduleADemo")}
                className="w-28 py-2 bg-blueColor font-fontPoppins text-xs font-normal text-white rounded-full"
              >
                See Demo <i className="fa-solid fa-circle-play"></i>
              </button> */}
              <button
                onClick={() => navigate("/scheduleADemo")}
                className="sm:w-28 py-1 px-1 sm:py-2 bg-blueColor font-fontPoppins text-xs font-normal text-white rounded-full flex items-center justify-center"
              >
                <span className="hidden sm:inline">See Demo</span>
                <i className="fa-solid fa-circle-play sm:ml-2"></i>
              </button>
            </>
          )}
        </div>

        <div className="ml-auto flex items-center xss:justify-center">
          <span className="cursor-pointer flex justify-center items-center gap-3 me-3">
            {!hideElements && !hideProviderElement && (
              <NavLink
                to="/patientLanding"
                className={({ isActive }) =>
                  `py-5 xss:py-3 font-fontPoppin text-sm text-black font-semibold border-b-4 mb-1 ${
                    isActive ? "border-blue-500" : "border-white"
                  }`
                }
              >
                For Patients
              </NavLink>
            )}
            <NavLink
              to="/"
              className={({ isActive }) =>
                `py-5 xss:py-3 font-fontPoppin text-sm text-black font-semibold border-b-4 mb-1 ${
                  isActive ? "border-blue-500" : "border-white"
                }`
              }
            >
              For Providers
            </NavLink>
            {!showExtraElements && (
              <NavLink
                to="/getpricing"
                className={({ isActive }) =>
                  `py-5 xss:py-3 font-fontPoppin text-sm text-black font-semibold border-b-4 mb-1 ${
                    isActive ? "border-blue-500" : "border-white"
                  }`
                }
              >
                Pricing
              </NavLink>
            )}
          </span>
          {!hideElements && !hideProviderElement && (
            <span className="w-48 h-9 px-2 flex justify-center bg-gray-100 rounded-full items-center text-xs leading-normal m-1">
              <img src={"./loc.png"} width="24px" alt="Location Icon" />
              {/* Martins Ferry, OH */}
              {locationName}
            </span>
          )}
          {/* <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
              <Autocomplete 
                onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                onPlaceChanged={handlePlaceChanged}
              >
                 <input
                  className="w-25 h-9 px-2 ml-2 focus:outline-none font-fontPoppins text-xs text-gray-500 rounded-full border border-gray-300"
                  type="text"
                  placeholder="Location..."
                  value={location}
                  onChange={e => setLocation(e.target.value)}
                />
              </Autocomplete>
            </LoadScript> */}
          <span className="cursor-pointer flex justify-center items-center gap-3 me-3 mb-2.5">
            {providerUser && (
              <img
                src={`${apiUrl}/${ProfilePic}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/placeholder.png";
                }}
                alt=""
                className="w-10 h-10 rounded-full object-cover"
              />
            )}
            <p className="font-fontPoppin text-sm text-black font-semibold">
              {userName}
            </p>
            {/* <Link
              onClick={() => login()}
              className="py-5 xss:py-3 font-fontPoppin text-sm text-black font-medium border-b-4 border-white hover:border-b-4 hover:border-blueColor hover:border-solid"
            >
              Log In
            </Link> */}
          </span>
          {/* <span className="cursor-pointer">
            <i class="fa-solid fa-search fa-lg"></i>
          </span> */}
        </div>
      </div>

      <div>
        {state.type2.isVisible && (
          <>
            <nav
              className="w-80 h-screen z-30 fixed inset-0 bg-white"
              style={paddingNav}
            >
              <header className="w-full flex justify-between">
                <img
                  src={"./samara-logo.png"}
                  alt="logo"
                  className="max-w-full"
                  width="133px"
                />
                <span
                  className="cursor-pointer hover:text-blueColor"
                  onClick={() =>
                    dispatch({ type: ActionTypes.HIDE, itemType: "type2" })
                  }
                >
                  <i className="fa-solid fa-xmark fa-lg"></i>
                </span>
              </header>
              {accountType !== "Patient" && (
                <div className="w-full mt-4">
                  <h1 className="font-fontPoppins py-3 text-sm font-semibold text-gray-500 border border-gray-800 border-solid border-l-0 border-t-0 border-r-0">
                    {/* Provider */}
                    {getAccountTypeHeading()}
                  </h1>
                </div>
              )}
              <main
                className="w-full h-full overflow-auto flex flex-col"
                style={{ paddingBottom: "200px" }}
              >
                {accountType !== "Patient" && (
                  <div className="w-full">
                    {providerUser && (
                      <>
                        <div className="w-full flex mt-4">
                          <Link
                            to="/"
                            className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                          >
                            Home
                          </Link>
                        </div>
                        <div className="w-full flex mt-4">
                          <Link
                            to="/Dashboard"
                            className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                          >
                            {/* Dashboard */}
                            Insights & Analytics
                          </Link>
                        </div>
                        <div className="w-full flex justify-center items-center mt-2">
                          <button
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.SHOW,
                                itemType: "type3",
                              })
                            }
                            className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor"
                          >
                            Accounts <i className="fa-solid fa-angle-right"></i>
                          </button>
                        </div>
                      </>
                    )}

                    {state.type3.isVisible && (
                      <div className="w-80 h-screen z-40 fixed left-0 top-0 bg-white p-4">
                        <header className="w-full flex justify-between">
                          <img
                            src={"./samara-logo.png"}
                            alt="logo"
                            width="133px"
                            className="max-w-full"
                          />
                          <span
                            className="cursor-pointer hover:text-blueColor"
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.HIDE,
                                itemType: "type3",
                              })
                            }
                          >
                            <i className="fa-solid fa-xmark fa-lg"></i>
                          </span>
                        </header>
                        <div
                          className="w-full h-full mt-4 overflow-auto flex flex-col"
                          style={{ paddingBottom: "200px" }}
                        >
                          <h1
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.HIDE,
                                itemType: "type3",
                              })
                            }
                            className="text-left cursor-pointer font-fontPoppins text-base font-semibold py-3 hover:text-blueColor"
                          >
                            <i className="fa-solid fa-arrow-left"></i> Accounts
                          </h1>
                          <ul className="list-none font-fontPoppins text-sm ps-4 mt-8">
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/CreateClinic">Organization Info</Link>
                            </li>
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/providerInfo">Practitioner Info</Link>
                            </li>
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/serviceProviderMappingProfile">
                                Appoinment Provider Mapping
                              </Link>
                            </li>

                            {/* {assistantInfoLink && ( */}
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/assistantInfo">Front Office Team</Link>
                            </li>
                            {/* )} */}
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/insuranceInfo">Insurance Info</Link>
                            </li>
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/clinicmedia">
                                Scheduling Widget & Hyperlink
                              </Link>
                            </li>
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/assistantProviderMapping">
                                Front Office Team Provider Mapping
                              </Link>
                            </li>
                            {MySubscriptionsLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/mySubscription">
                                  My Subscriptions
                                </Link>
                              </li>
                            )}
                            {/* <li className="mb-4 hover:text-blueColor">
                              <Link to="/AssistanceProfile">
                                Assistance Profile
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="w-full mt-2">
                      {providerUser ? (
                        <>
                          {/* <div className="w-full flex mt-2">
                            <Link
                              to="/providerCalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Provider Calender
                            </Link>
                          </div> */}
                          <div className="w-full flex mt-2">
                            <Link
                              to="/newcalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Provider Calender
                            </Link>
                          </div>
                          {/* <div className="w-full flex mt-2">
                            <Link
                              to="/newcalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              New Calender
                            </Link>
                          </div> */}
                          <div className="w-full flex mt-4">
                            <Link
                              to="/ClinicBookingHistory"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Booking Details
                            </Link>
                          </div>
                          {accountType === "Practitioners" && (
                            <div className="w-full flex mt-4">
                              <Link
                                to="/ProviderProfile"
                                className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                              >
                                Profile
                              </Link>
                            </div>
                          )}
                          {accountType === "Assistances" && (
                            <div className="w-full flex mt-4">
                              <Link
                                to="/AssistanceProfile"
                                className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                              >
                                Profile
                              </Link>
                            </div>
                          )}

                          <div className="w-full flex mt-4">
                            <Link
                              onClick={handleProviderLogout}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Logout
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-full flex mt-4">
                            <Link
                              to="/"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Home
                            </Link>
                          </div>
                          <div className="w-full flex mt-2">
                            <Link
                              to="/login"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log In
                            </Link>
                          </div>

                          <div className="w-full flex justify-center items-center mt-2">
                            <Link
                              to="/outpatientPracticeDetails2"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </>
                      )}
                    </div>

                    {state.type1.isVisible && <></>}

                    {/*  */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                    <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                      Medication <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                    <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                      Symptom <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                    <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                      Service <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                    <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                      Life Situation <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </div> */}
                  </div>
                )}
                {accountType !== "Clinic" &&
                  accountType !== "Practitioners" &&
                  accountType !== "Assistances" && (
                    <div className="w-full mt-6">
                      <div className="w-full">
                        <h1 className="font-fontPoppins py-3 text-sm font-semibold text-gray-500 border border-gray-800 border-solid border-l-0 border-t-0 border-r-0">
                          Patient
                        </h1>
                      </div>
                      {user ? (
                        <>
                          <div className="w-full flex mt-4">
                            <Link
                              to="/patientLanding"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Home
                            </Link>
                          </div>
                          {/* <div className="w-full flex justify-center items-center mt-2">
                            <Link
                              to=""
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Profile
                            </Link>
                          </div> */}
                          <div className="w-full flex justify-center items-center mt-2">
                            <Link
                              to="/BookingHistory"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Booking Details
                            </Link>
                          </div>
                          <div className="w-full flex mt-2">
                            <Link
                              onClick={handlePatientLogout}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log out
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-full flex mt-4">
                            <Link
                              to="/patientLanding"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Home
                            </Link>
                          </div>
                          <div className="w-full flex mt-4">
                            <Link
                              onClick={() => {
                                dispatch({
                                  type: ActionTypes.HIDE,
                                  itemType: "type2",
                                });
                                Login();
                              }}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log In
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                {accountType == "Patient" && (
                  <div className="w-full mt-6">
                    <>
                      <div className="w-full">
                        <h1 className="font-fontPoppins py-3 text-sm font-semibold text-gray-500 border border-gray-800 border-solid border-l-0 border-t-0 border-r-0">
                          Provider
                        </h1>
                      </div>
                      {/* <div className="w-full flex justify-center items-center mt-4">
                  <Link
                    to="/chooseAppoinment/:type/:name"
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Book Appoinment
                  </Link>
                </div> */}

                      <div className="w-full flex mt-4">
                        <Link
                          to="/"
                          className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                        >
                          Home
                        </Link>
                      </div>
                      <div className="w-full flex mt-4">
                        <Link
                          to="/login"
                          className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                        >
                          Log In
                        </Link>
                      </div>

                      <div className="w-full flex justify-center items-center mt-2">
                        <Link
                          to="/outpatientPracticeDetails2"
                          className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                        >
                          Sign Up
                        </Link>
                      </div>
                    </>
                  </div>
                )}
                {/* <div className="w-full mt-6">
                  <div className="font-fontPoppins text-base font-semibold ">
                    Account
                  </div>
                  <div className="w-full flex mt-6">
                    <a
                      href={" "}
                      className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                    >
                      Log In
                    </a>
                  </div>

                  <div className="w-full flex justify-center items-center mt-6">
                    <Link
                          to="/chooseAppoinment"
                          className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                        >
                          Sign Up
                        </Link>
                    <span className="w-full font-fontPoppins text-f9 text-white rounded-full font-normal bg-green-500 py-1 px-1">
                      20% OFF WELCOME COUPON
                    </span>
                  </div>

                  <div className="w-full flex mt-6">
                    <a
                      href={" "}
                      className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                    >
                      Refer a Friend
                    </a>
                  </div>
                </div> */}
              </main>
            </nav>
            <div className="bg-black opacity-30 z-20 fixed left-0 top-0 inset-0"></div>
          </>
        )}
      </div>
    </header>
  );
}

export default PatientsLandingPageHeader;
