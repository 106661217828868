import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { loginPatient, selectPatient } from "../../redux/actions/userSlice";
import { postData, apiUrl } from "../../Networking/Api";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Header from "../Header";
import Footer from "../Footer";
//import ThirdPartyHeader from "./ThirdPartyHeader";
//import ThirdPartyFooter from "./ThirdPartyFooter";
import Loader from "../Loader";
import Loader2 from "../Loader2";
import moment from "moment-timezone";
import ClinicBookingHeader from "./ClinicBookingHeader";
import ClinicBookingFooter from "./ClinicBookingFooter";
import { decrypt, encrypt } from "../CryptoUtil";
import { logEvent } from "../../ga";

function ClinicAddBookingDetails() {
  const { clinicName, locations } = useParams();
  return (
    <>
      <ClinicBookingHeader
        clinicNameParam={clinicName}
        cityStreetNameParam={locations}
      />
      <AddBookingDetails />
      <ClinicBookingFooter />
    </>
  );
}

function AddBookingDetails() {
  const imgW = "40px";
  const googleIconW = "24px";
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const location = useLocation();
  const { state } = location || {};
  const { symptom } = state || {};
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState();
  const patientData = useSelector(selectPatient);
  const {
    clinicName,
    locations,
    id,
    appointmentType,
    insuranceType,
    patientType,
  } = useParams();
  ////  console.log("appointmentType..",appointmentType)
  const appointmentTypeId = decrypt(decodeURIComponent(appointmentType));
  ////  console.log("appid..",appointmentTypeId)
  const today = new Date();
  const maxDate = new Date();
  // const today = new Date("Sun Aug 11 2024 15:32:45 GMT+0000");
  // const maxDate = new Date("Sun Aug 11 2024 15:32:45 GMT+0000");
  maxDate.setFullYear(today.getFullYear() + 1);
  const type = "clinicServices";
  const [apiResponseCode, setApiResponseCode] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [appoinmentName, setAppoinmentName] = useState("");
  const [patientTypeName, setPatientTypeName] = useState("");
  const [insurance, setInsurance] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [locationName, setLocationName] = useState("");
  const [iplocation, setIpLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [clinicId, setClinicId] = useState("");
  const [pratitionerLocationId, setPractitionerLocarionId] = useState("");
  useEffect(() => {
    const fetchClinicdata = async () => {
      try {
        setLoading(true);
        const response = await postData("clinics/getClinicInfo", {
          clinicName: clinicName,
          cityStreetName: locations,
        });
        //const data = response.data;

        if (response.code === 0) {
          setLoading(false);
          Swal.fire("Clinic location not found").then(() => {
            navigate("/patientLanding"); // Redirect to patient landing page
          });
        } else if (response.code === 1) {
          setLoading(true);
          setClinicId(response.clinics.id);
          //  console.log("pracloc..", response.clinic_locations_data.id);
          setPractitionerLocarionId(response.clinic_locations_data.id);
          getProviderTimeSlot(
            response.clinics.id,
            response.clinic_locations_data.id
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchClinicdata();
  }, [clinicName]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo")) || [];
    setDetails(userDetails);
    const savedAppointment = localStorage.getItem("selectedAppointment");
    const savedSlot = localStorage.getItem("selectedSlot");
    const savedDate = localStorage.getItem("selectedDate");
    const savedDoctor = localStorage.getItem("selectedDoctor");

    if (savedAppointment && savedSlot) {
      setSelectedAppointment(JSON.parse(savedAppointment));
      setSelectedSlot(JSON.parse(savedSlot));
    }
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
    if (savedDoctor) {
      setSelectedDoctor(JSON.parse(savedDoctor));
    }
  }, []);

  useEffect(() => {
    if (insuranceType === "other" || insuranceType === "noInsurance") {
      setInsurance(insuranceType === "other" ? "Other" : "No Insurance");
    } else {
      const getInsuranceType = async () => {
        try {
          const payload = {
            modelName: "clinic_insurances",
            whereCondition: {
              id: insuranceType,
            },
            relations: [
              {
                module: "insurance_masters",
              },
              {
                module: "health_insurance_carrier_master",
              },
            ],
          };
          const response = await postData("masters/getMasterList", payload);

          setInsurance(response.data[0]);
          // setLoading(false);
        } catch (error) {
          // setLoading(false);
          console.error("Error fetching insurance options:", error);
        }
      };
      getInsuranceType();
    }
  }, [insuranceType]);

  useEffect(() => {
    // setLoading(true);
    if (patientType === "newPatient" || patientType === "existingPatient") {
      setPatientTypeName(
        patientType === "newPatient" ? "New Patient" : "Existing Patient"
      );
    }
    const getAppointmentType = async () => {
      try {
        // setLoading(true);
        const payload = {
          modelName: "clinic_services",
          whereCondition: {
            id: appointmentTypeId, //appointmentType,
          },
        };
        const response = await postData("/masters/getMasterList", payload);

        setAppoinmentName(response.data[0].services);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching insurance options:", error);
      }
    };
    getAppointmentType();
  }, [appointmentTypeId, patientType]);

  useEffect(() => {
    const getLocationFromIP = async () => {
      try {
        // setLoading(true);
        var currentTimeZone = localStorage.getItem("currentTimeZone");

        if (
          currentTimeZone == "null" ||
          currentTimeZone == null ||
          currentTimeZone == ""
        ) {
          try {
            // setLoading(true);
            const response = await fetch("https://ipapi.co/json/");
            if (!response.ok) {
              // setLoading(false);
              throw new Error("Network response was not ok");
            }
            const IpData = await response.json();

            setIpLocation({
              lat: IpData.latitude,
              lon: IpData.longitude,
            });

            const locationName = `${IpData.city}, ${IpData.region_code}`;
            setLocationName(locationName);

            const timeZone = `${IpData.utc_offset}, ${IpData.timezone}`;
            setTimeZone(timeZone);
            localStorage.setItem("currentTimeZone", timeZone);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          setTimeZone(JSON.parse(currentTimeZone));
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching IP location:", error);
        // setLoading(false);
      }
    };
    getLocationFromIP();
  }, []);

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  // useEffect(() => {
  //   getProviderTimeSlot(clinicId, pratitionerLocationId);
  // }, [selectedDate, timeZone]);

  useEffect(() => {
    // setLoading(true);

    const availableDoctorsList = doctors.filter(
      (doctor) => doctor.practitionerCalendarsData.length > 0
    );
    setAvailableDoctors(availableDoctorsList);
    console.log("availableDoctors", availableDoctors);

    // setLoading(false);
  }, [selectedDate, doctors]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getProviderTimeSlot = async (clinicId, pratitionerLocationId) => {
    //  console.log("loading", loading);
    try {
      setLoading(true);
      const currentDate = new Date();
      let selectedDate;
      const savedDate = localStorage.getItem("selectedDate");
      if (savedDate) {
        setLoading(true);
        selectedDate = new Date(savedDate);
      } else {
        setLoading(true);
        selectedDate = currentDate;
      }

      function formatCustomDate(date) {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const currentTimeZone = localStorage.getItem("currentTimeZone");
        var patientTimeZone = currentTimeZone.split(",")[1];
        var timeZone = patientTimeZone.trim();
        console.log("timeZone--->", timeZone);
        if (formatDate(new Date()) === formatDate(selectedDate)) {
          return `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} ${currentTimeZone}`;
        } else {
          return `${dayOfWeek} ${month} ${day} ${year} 00:00:00 ${currentTimeZone}`;
        }
      }
      function formatUTCDate(date) {
        const now = date;

        const padToTwoDigits = (num) => num.toString().padStart(2, "0");

        const padToThreeDigits = (num) => num.toString().padStart(3, "0");

        const year = now.getUTCFullYear();
        const month = padToTwoDigits(now.getUTCMonth() + 1);
        const day = padToTwoDigits(now.getUTCDate());
        const hours = padToTwoDigits(now.getUTCHours());
        const minutes = padToTwoDigits(now.getUTCMinutes());
        const seconds = padToTwoDigits(now.getUTCSeconds());
        const milliseconds = padToThreeDigits(now.getUTCMilliseconds());

        const utcDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

        if (formatDate(new Date()) === formatDate(selectedDate)) {
          return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        } else {
          return `${year}-${month}-${day}T00:00:00.00Z`;
        }
      }

      const currentTimeZone = localStorage.getItem("currentTimeZone");

      const payload = {
        id: clinicId,
        type: type,
        date: formatDate(selectedDate),
        patientType: patientType,
        insuranceType: insuranceType,
        appointmentType: appointmentTypeId,
        currentTimeZone: currentTimeZone,
        date_Time: formatCustomDate(currentDate),
        date_TimeUTC: formatUTCDate(currentDate),
        practitioner_location_id: pratitionerLocationId,
      };
      setLoading(true);
      const apiResponse = await postData(
        "patients/SearchProviderNewNextAvailability",
        payload
      );

      if (apiResponse.code === 1) {
        setDoctors(apiResponse.data);
        setLoading(false);
        setApiResponseCode(true);
        return apiResponse.data;
      } else if (apiResponse.code === 0 && !apiResponse.success) {
        // setApiResponseCode(false);
        setDoctors([]);
        setApiResponseCode(true);
        setLoading(false);
      } else {
        setLoading(false);
        return null;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error searching provider:", error);
      return null;
    }
  };

  const handleDateChange = (date) => {
    setLoading(true);
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedDoctor");
    setSelectedSlot(null);
    setSelectedDoctor(null);
    setSelectedDate(date);
    localStorage.setItem("selectedDate", date.toISOString());
    getProviderTimeSlot(clinicId, pratitionerLocationId);
  };

  const handleSlotClick = (doctorId, practitionerCalendarsData, slot) => {
    const selectedSlotData = practitionerCalendarsData.find(
      (data) => data.available_date_time_new === slot.available_date_time_new
    );

    setSelectedAppointment(selectedSlotData);
    setSelectedSlot(slot);
    setSelectedDoctor(doctorId);

    localStorage.setItem(
      "selectedAppointment",
      JSON.stringify(selectedSlotData)
    );
    localStorage.setItem("selectedSlot", JSON.stringify(slot));
    localStorage.setItem("selectedDoctor", JSON.stringify(doctorId));

    if (user) {
      const encryptedappointmentTypeId = encrypt(appointmentTypeId.toString());
      const urlSafeAppointmentTypeId = encodeURIComponent(
        encryptedappointmentTypeId
      );
      const url = `/clinicpersonalappoinmentdetails/${clinicName}/${locations}/${patientType}/${insuranceType}/${urlSafeAppointmentTypeId}`;
      navigate(url, { state: selectedAppointment });
    }
  };

  const handleClickManualDetails = () => {
    if (!selectedSlot) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select A Practitioner's Appointment Slot!",
      });
      return;
    }

    const cleanedPatientType = patientType.replace(/\s/g, "");
    const cleanedInsuranceType = insuranceType.replace(/\s/g, "");
    const cleanedAppointmentType = appointmentType.replace(/\s/g, "");
    const encryptedappointmentTypeId = encrypt(appointmentTypeId.toString());
    const urlSafeAppointmentTypeId = encodeURIComponent(
      encryptedappointmentTypeId
    );
    const url = `/clinicpersonalappoinmentdetails/${clinicName}/${locations}/${patientType}/${insuranceType}/${urlSafeAppointmentTypeId}`;
    navigate(url, { state: selectedAppointment });
  };

  const Login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        // setLoading(true);
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        const userInfo = userInfoResponse.data;
        const email = userInfo.email;
        const l_name = userInfo.family_name;
        const f_name = userInfo.given_name;

        const checkEmailExists = async (email) => {
          const payload = {
            modelName: "patients",
            whereCondition: { email: email },
          };

          try {
            const apiResponse = await postData(
              "masters/getMasterList",
              payload
            );
            if (apiResponse.code == 1) {
              // setLoading(false);
              localStorage.setItem(
                "userCreateInfo",
                JSON.stringify(apiResponse.data[0])
              );
              localStorage.setItem("accountType", "Patient");
              Dispatch(
                loginPatient({
                  isLoggedIn: true,
                })
              );
              logEvent("User", "Login", email);
              localStorage.removeItem("selectedSlot");

              if (selectedSlot) {
                const encryptedappointmentTypeId = encrypt(
                  appointmentTypeId.toString()
                );
                const urlSafeAppointmentTypeId = encodeURIComponent(
                  encryptedappointmentTypeId
                );
                const url = `/clinicpersonalappoinmentdetails/${clinicName}/${locations}/${patientType}/${insuranceType}/${urlSafeAppointmentTypeId}`;
                window.location.href = url;
              }

              return true;
            }
          } catch (error) {
            // setLoading(false);
            console.error("Error checking email existence:", error);
            return false;
          }
        };

        const emailExists = await checkEmailExists(email);

        if (!emailExists) {
          const createPayload = {
            modelName: "patients",
            inputData: {
              email: email,
              first_name: f_name,
              last_name: l_name,
            },
          };

          try {
            const createResponse = await postData(
              "masters/createAndUpdateMaster",
              createPayload
            );
            localStorage.setItem(
              "userCreateInfo",
              JSON.stringify(createResponse.data[0])
            );
            Dispatch(
              loginPatient({
                isLoggedIn: true,
              })
            );
            logEvent("User", "Login", email);
            localStorage.removeItem("selectedSlot");

            if (selectedSlot) {
              const encryptedappointmentTypeId = encrypt(
                appointmentTypeId.toString()
              );
              const urlSafeAppointmentTypeId = encodeURIComponent(
                encryptedappointmentTypeId
              );
              const url = `/clinicpersonalappoinmentdetails/${clinicName}/${locations}/${patientType}/${insuranceType}/${urlSafeAppointmentTypeId}`;
              window.location.href = url;
            }

            //  console.log("New record created:", createResponse.data);
          } catch (error) {
            console.error("Error creating new record:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
  });

  const getTimeZone = (offset) => {
    const timezoneMap = {
      "-12": "Etc/GMT+12",
      "-11": "Pacific/Midway",
      "-10": "Pacific/Honolulu",
      "-9": "America/Anchorage",
      "-8": "America/Los_Angeles",
      "-7": "America/Denver",
      "-6": "America/Chicago",
      "-5": "America/New_York",
      "-4": "America/Santiago",
      "-3": "America/Argentina/Buenos_Aires",
      "-2": "Etc/GMT+2",
      "-1": "Etc/GMT+1",
      0: "Etc/GMT",
      1: "Europe/London",
      2: "Europe/Berlin",
      3: "Europe/Moscow",
      4: "Asia/Dubai",
      5: "Asia/Karachi",
      6: "Asia/Dhaka",
      7: "Asia/Bangkok",
      8: "Asia/Shanghai",
      9: "Asia/Tokyo",
      10: "Australia/Sydney",
      11: "Pacific/Noumea",
      12: "Pacific/Fiji",
    };

    const timezone = timezoneMap[offset.toString()];

    if (!timezone) return null;

    const parts = timezone.split("/");
    return parts.length > 1 ? parts[0] : timezone;
  };
  const containerRefs = useRef({});

  useEffect(() => {
    doctors.forEach((doctor) => {
      if (!containerRefs.current[doctor.id]) {
        containerRefs.current[doctor.id] = React.createRef();
      }
    });
  }, [doctors]);
  const scrollLeft = (doctorId) => {
    if (containerRefs.current[doctorId]) {
      containerRefs.current[doctorId].current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (doctorId) => {
    if (containerRefs.current[doctorId]) {
      containerRefs.current[doctorId].current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      {loading && <Loader2 />}
      {/* ) : ( */}
      <>
        <section className="w-full mt-4">
          <div className="m-6 mb-1">
            <span
              onClick={() => {
                navigate(`/bookAppoinment/${clinicName}/${locations}`);
              }}
              className="font-fontPoppins text-sm font-semibold cursor-pointer"
            >
              <i className="fa-solid fa-long-arrow-left text-gray-300"></i> Book
              An Appointment
            </span>
          </div>
          <div className="bg-gray-100 rounded-lg m-6 mt-3 py-2">
            <div className="w-full px-4 flex items-center flex-wrap gap-2">
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-700">
                  Patient Type
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {patientTypeName}
                </div>
              </div>
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-700">
                  Appointment Type
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {appoinmentName}
                </div>
              </div>
              {(insuranceType === "other" ||
                insuranceType === "noInsurance") && (
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <div className="font-fontPoppins text-f10 font-normal text-gray-700">
                    Insurance Type
                  </div>
                  <div className="font-fontPoppins text-xs font-medium">
                    {insurance}
                  </div>
                </div>
              )}
              {insuranceType !== "other" && insuranceType !== "noInsurance" && (
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <div className="font-fontPoppins text-f10 font-normal text-gray-700">
                    Insurance Carrier
                  </div>
                  <div className="font-fontPoppins text-xs font-medium">
                    {
                      insurance?.health_insurance_carrier_master
                        ?.health_insurance_carrier_name
                    }
                  </div>
                </div>
              )}
              {insuranceType !== "other" && insuranceType !== "noInsurance" && (
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <div className="font-fontPoppins text-f10 font-normal text-gray-700">
                    Insurance Plan
                  </div>

                  <div className="font-fontPoppins text-xs font-medium">
                    {insurance?.insurance_masters?.insurance_name}
                  </div>
                </div>
              )}
            </div>

            <div className="container">
              <div className="w-full  rounded-lg">
                <div className="w-full flex justify-center items-center gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200">
                  <div className="w-3/12 font-fontPoppins text-sm font-semibold ">
                    Select Date
                  </div>
                  <div className="w-3/4 flex items-center">
                    <i className="fa-solid fa-calendar-alt fa-sm mr-1 mb-1"></i>
                    <ReactDatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="EEEE, dd MMMM"
                      className="font-fontPoppins text-sm  fontmedium cursor-pointer p-1 rounded-md pl-3"
                      minDate={today}
                      maxDate={maxDate}
                      onClick={(e) => e.preventDefault()}
                      placeholderText="Please select a date"
                    />
                  </div>
                </div>

                {apiResponseCode === true && symptom !== null && (
                  <>
                    {availableDoctors.length > 0 ? (
                      availableDoctors.map((doctor, index) => (
                        <div
                          key={index}
                          className="w-full p-3 border border-t-0 border-l-0 border-r-0 border-gray-200 sm:flex sm:items-center sm:gap-2"
                        >
                          <div className="responsive-style w-3/12 xss:w-full flex items-center gap-2">
                            <img
                              src={
                                `${apiUrl}/${doctor.photo}` ||
                                "/placeholder.png"
                              }
                              width={imgW}
                              alt="Doctor Pic"
                              className="rounded-full"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                            />
                            <div className="w-full">
                              <div className="font-fontPoppins text-f10 font-normal">
                                {doctor.taxonomy_description}
                              </div>
                              <div className="font-fontPoppins text-xs font-semibold">
                                <span>
                                  {doctor.f_name} {doctor.l_name}
                                </span>{" "}
                              </div>
                            </div>
                          </div>

                          {/* <div className="w-3/4 xss:w-full xss:mt-3 flex flex-row overflow-auto items-center justify-between gap-2">
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span className="cursor-pointer px-1">
                                  <i className="fa-solid fa-angle-left"></i>
                                </span>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 &&
                              doctor.practitionerCalendarsData.map((slot) => (
                                <div
                                  key={slot.id}
                                  className={`w-32 bg-white hover:bg-blueColor cursor-pointer hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center ${
                                    user ? "bg-white text-black" : ""
                                  }`}
                                  style={
                                    selectedSlot &&
                                    !user &&
                                    selectedSlot.available_date_time_new ===
                                      slot.available_date_time_new &&
                                    selectedDoctor === doctor.id
                                      ? {
                                          backgroundColor: "blue",
                                          color: "white",
                                          minWidth: "80px",
                                          marginBottom: "5px",
                                        }
                                      : {
                                          minWidth: "80px",
                                          marginBottom: "5px",
                                        }
                                  }
                                  onClick={() =>
                                    handleSlotClick(
                                      doctor.id,
                                      doctor.practitionerCalendarsData,
                                      slot
                                    )
                                  }
                                >
                                  {slot.available_date}
                                  <br />
                                  {slot.available_time}
                                </div>
                              ))}

                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData.length > 0 &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 1 && (
                                <div
                                  key={doctor.practitionerCalendarsData[0].id}
                                  className={`w-32 bg-grayColor cursor-pointer hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center cursor-pointer}`}
                                  onClick={() => {
                                    handleDateChange(
                                      new Date(
                                        doctor.practitionerCalendarsData[0].available_date_time
                                      )

                                    );
                                  }}
                                >
                                  Next Availability
                                  <br />
                                  {
                                    doctor.practitionerCalendarsData[0]
                                      .available_date
                                  }
                                </div>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span className="cursor-pointer px-1">
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>
                              )}
                          </div> */}
                          <div className="responsive-style md:w-3/4 w-3/4 xss:w-full xss:mt-3 flex flex-row items-center  gap-2">
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => scrollLeft(doctor.id)}
                                >
                                  <i className="fa-solid fa-angle-left"></i>
                                </span>
                              )}
                            <div
                              ref={containerRefs.current[doctor.id]}
                              className="flex flex-row overflow-hidden items-center gap-2"
                            >
                              {doctor.practitionerCalendarsData &&
                                doctor.practitionerCalendarsData[0]
                                  .nextavailable === 0 &&
                                doctor.practitionerCalendarsData.map((slot) => (
                                  <div
                                    key={slot.id}
                                    className={`w-32 bg-white hover:bg-blueColor cursor-pointer hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center ${
                                      user ? "bg-white text-black" : ""
                                    }`}
                                    style={
                                      selectedSlot &&
                                      !user &&
                                      selectedSlot.available_date_time_new ===
                                        slot.available_date_time_new &&
                                      selectedDoctor === doctor.id
                                        ? {
                                            backgroundColor: "blue",
                                            color: "white",
                                            minWidth: "80px",
                                            marginBottom: "5px",
                                          }
                                        : {
                                            minWidth: "80px",
                                            marginBottom: "5px",
                                          }
                                    }
                                    onClick={() =>
                                      handleSlotClick(
                                        doctor.id,
                                        doctor.practitionerCalendarsData,
                                        slot
                                      )
                                    }
                                  >
                                    {slot.available_date}
                                    <br />
                                    {slot.available_time}
                                  </div>
                                ))}
                            </div>
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData.length > 0 &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 1 && (
                                <div
                                  key={doctor.practitionerCalendarsData[0].id}
                                  className={`w-32 bg-grayColor cursor-pointer hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center cursor-pointer}`}
                                  onClick={() => {
                                    handleDateChange(
                                      new Date(
                                        doctor.practitionerCalendarsData[0].available_date_time
                                      )
                                    );
                                  }}
                                >
                                  Next Availability
                                  <br />
                                  {
                                    doctor.practitionerCalendarsData[0]
                                      .available_date
                                  }
                                </div>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => scrollRight(doctor.id)}
                                >
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>
                              )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center items-center h-full p-10 pb-15">
                        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center space-x-4">
                          <i className="fa fa-exclamation-circle text-red-500 text-3xl "></i>
                          <div>
                            <p className="font-poppins text-lg font-semibold text-gray-700">
                              No Doctors Available For The Selected Date.
                            </p>
                            {/* <p className="font-poppins text-sm text-gray-500 mt-2">
                              Please select another date or try searching for
                              doctors in a different location. If you need
                              immediate assistance, consider visiting a nearby
                              clinic or hospital.
                            </p> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* <div className="w-full sm:flex sm:items-center sm:justify-center sm:gap-3 md:flex md:items-center md:justify-center md:gap-3 mt-4"> */}
              {availableDoctors.length > 0 && (
                <>
                  {!user && (
                    <div className="flex flex-col items-center gap-2 md:w-full md:flex-row md:items-center md:justify-center md:gap-3 mt-4">
                      <div className="font-fontPoppins text-sm font-medium xss:text-center">
                        For A Faster Booking Experience
                      </div>
                      <span className="px-8 py-3 bg-white shadow-sm cursor-pointer rounded-full flex items-center gap-3 justify-center font-fontPoppins text-sm font-medium text-gray-500 xss:mt-2">
                        <img
                          src="/icons8-google-48.png"
                          width={googleIconW}
                          alt="Google Icon"
                        />{" "}
                        <Link
                          onClick={() => {
                            if (!selectedSlot) {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Please Select A Practitioner's Appointment Slot!",
                              });
                              return;
                            }
                            Login();
                          }}
                        >
                          Continue With Google
                        </Link>
                      </span>
                    </div>
                  )}

                  <div className="w-full mt-5 text-center">
                    <span
                      onClick={handleClickManualDetails}
                      className={`font-fontPoppins text-sm font-medium text-blueColor hover:text-blue-500 cursor-pointer ${
                        user ? "hidden" : ""
                      }`}
                      style={{ display: user ? "none" : "inline" }}
                    >
                      Or Enter Your Details Manually
                      <i className="fa-solid fa-long-arrow-right"></i>
                    </span>
                  </div>
                </>
              )}
              {/* <div className="w-full mt-6 lg:min-h-240">
                <div className="w-2/5 bg-gray-100 rounded-lg mx-auto p-4 md:flex md:items-center md:justify-evenly xss:w-full">
                  <div className="font-fontPoppins text-base   font-bold xss:text-center md:text-center xss:mb-1 md:mb-1">
                    Not Able To Find Your Appointment?
                  </div>
                  <div className="bg-white rounded-full shadow-sm py-3 px-4 xss:flex xss:items-center xss:justify-between xss:gap-2 sm:flex sm:items-center sm:justify-between sm:gap-3 md:flex md:items-center md:justify-center md:gap-3">
                    <span className="font-fontPoppins text-xs font-bold">
                      Search On
                    </span>{" "}
                    <img
                      src="/samara-logo.png"
                      className="max-w-full h-auto md:w-20 lg:w-28"
                      alt="Sammara Well Logo"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </>
      {/* )} */}
    </>
  );
}

export default ClinicAddBookingDetails;
