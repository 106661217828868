import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import CardStates from "./CardStates";
import BarChart from "./BarChart";
import Tabs from "./Tabs";
import DonutChart from "./DonutChart";
import LineChart from "./LineChart";
import Swal from "sweetalert2";
import tippy from "tippy.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiUrl, baseUrl, getData, postData } from "../Networking/Api";
import Loader2 from "./Loader2";
import PopupModal from "./PopupModal";

const ReviewsAnalyticsPage = () => {
  const tabs = [
    { id: "home", label: "Home" },
    { id: "scheduling", label: "Practice Management" },
    { id: "review", label: "Patient Satisfaction" },
  ];
 const location = useLocation();

 const searchParams = new URLSearchParams(location.search);
 const status = searchParams.get("status");
 const message = searchParams.get("message");
 const isMultilocation = searchParams.get("isMultilocation");
 const [IsMultiLocation, setIsMultiLocation] = useState(false);
  useEffect(() => {
    if (isMultilocation === "true") {
      setIsModalOpen(true);
      setIsMultiLocation(true);
    } else if (isMultilocation === "false") {
      setIsModalOpen(false);
      setIsMultiLocation(false);
    }
  }, [isMultilocation]);
 console.log("status:", status);
 console.log("message:", message);

  const [selectedReviewType, setSelectedReviewType] = useState("google");
  const [expandedReviews, setExpandedReviews] = useState({});
  const [selectedTab, setSelectedTab] = useState("All Time");
  const [selectedTabBar, setSelectedTabBar] = useState("All Time");
  const [selectedDonut, setSelectedDonut] = useState("All Time");
  const [selectedLineChart, setSelectedLineChart] = useState("All Time");
  const [bookedData, setBookedData] = useState("");
  const [cancelledData, setCancelledData] = useState("");
  const [rescheduleData, setRescheduleData] = useState("");
  const [confirmData, setConfirmData] = useState("");
  const [ratingData, setRatingData] = useState("");
  const [reviewData, setReviewData] = useState("");
  const [remidersData, setRemiderData] = useState("");
  const [ehrsamaraData, setEhrSamaraData] = useState("");
  const [samaraCount, setSamaraCount] = useState(0);
  const [ehrCount, setEhrCount] = useState(0);
  const [actPending,setActpendingData] = useState(0);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [reviewRatingData, setReviewsRatingData] = useState([]);
  const [lastWeekCount,setLastweekCount] = useState("");
  const [currentWeekCount,setCurrentweekCount] = useState("");
  const [percentageChange,setPercentChange] = useState("");
  const [lastWeekCancel, setLastweekCancel] = useState("");
  const [cancelCurrent,setCancelCurrent] = useState("");
  const [cancelPercent,setCancelPercent] = useState("");
  const [lastWeekReschdule, setLastweekReschedule] = useState("");
  const [rescheduleCurrent, setRescheduleCurrent] = useState("");
  const [reschedulePercent, setReschedulePercent] = useState("");
  const [lastWeekConfirm, setLastweekConfirm] = useState("");
  const [confirmCurrent, setConfirmCurrent] = useState("");
  const [confirmPercent, setConfirmPercent] = useState("");
  const [CurrentReviewRating, setCurrentRatingCount] = useState("");
  const [ReviewRatingPercent, setReviewRatingPercent] = useState("");
  const [lastReviewRating, setLastRatingCount] = useState("");

 const [isGoogleAccountSynced, setIsGoogleAccountSynced] = useState(false); 
 const [reviewCounts, setReviewCounts] = useState({ google: 0, samara: 0 });
 const [samaraLocations,setSamaraLocations] = useState([])
 const [googleLocations,setGoogleLocations] = useState([]);
 const [googleReviewsData,setGoogleReviewsData] = useState([]);
 const [samaraReviewData,setSamaraReviewData] = useState([]);
 const [googlereviewCount,setGoogleReviewCounts] = useState(0);
 const [samaraReviewCount,setSamaraCounts] = useState(0);
 const [googleAvgRating,setAverageRatingGoogle] = useState(0);
 const [samaraAvgRating,setAverageRatingSamara] = useState(0);
 const [isSyncPopup,setIsSyncPopup] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
      const locations = [
        { id: 1, label: "Location 1" },
        { id: 2, label: "Location 2" },
      ];

 const handleModalSubmit = async (mappings) => {
   console.log("Selected Location ID Mapping:", mappings);
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_google_business_mappings",
        inputData: {
          multipleData: mappings.map((mapping) => ({
            id: mapping.id,
            clinic_location_id: mapping.clinic_location_id,
          })),
        },
      };
        console.log("final submision...",payload);
        //return false;
        const response = await postData("masters/createAndUpdateMaster", payload);
        console.log("API Response:", response);
        setLoading(false);
        if (response.code == 1) {
          Swal.fire("Success", "Mappings Submitted Successfully!", "success");
          setIsGoogleAccountSynced(true);
          fetchGoogleReviews(1); 
        } else {
          setLoading(false);
          Swal.fire(
            "Error",
            "Failed To Submit Mappings. Please Try Again.",
            "error"
          );
        }
    } catch (error) {
        console.error("Error during API call:", error);
         setLoading(false);
    }
 };

 useEffect(() => {
  CheckGoogleBussiness();
  fetchgoogleLocations();
  fetchClinicLocations();
  CheckSyncPractitioner();
 }, []);
 const CheckSyncPractitioner = async () => {
   try {
    const accountType = localStorage.getItem("accountType"); // Get account type from localStorage
    if (accountType !== "Practitioners") {
      return; // Exit the function if accountType is not "Practitioner"
    }
     const refernceId = localStorage.getItem("refernceId");
     const payload = {
       modelName: "practitioners",
       whereCondition: {
         id: refernceId,
       },
     };
     const response = await postData("masters/getMasterList", payload);
    //  if (response && response.data && response.data.length > 0) {
      const { athenahealth_id, drchrono_id, modmed_id, is_calendar_sync,is_active_today } = response?.data[0];
      //  if (
      //    athenahealth_id === null &&
      //    drchrono_id === null &&
      //    modmed_id === null &&
      //   (is_calendar_sync === null || is_calendar_sync === 0) &&
      //    !sessionStorage.getItem("calendarSyncPopupShown")
      //  ) {
      //    //setIsSyncPopup(true);
      //    showPopup();
      //  }
      //  if (
      //    (is_active_today === null || is_active_today === 0) &&
      //    !sessionStorage.getItem("activeStatusPopupShown")
      //  ) {
      //    showAvailabilityPopup();
      //  }
     const calendarSyncRequired =
       athenahealth_id === null &&
       drchrono_id === null &&
       modmed_id === null &&
       (is_calendar_sync === null || is_calendar_sync === 0) &&
       !sessionStorage.getItem("calendarSyncPopupShown");

     // Check availability status popup condition
     const availabilityStatusRequired =
       (is_active_today === null || is_active_today === 0) &&
       !sessionStorage.getItem("activeStatusPopupShown");

     if (calendarSyncRequired) {
       showPopup(availabilityStatusRequired); // Pass the availability status condition to handle it later
     } else if (availabilityStatusRequired) {
       showAvailabilityPopup(); // Show availability popup if no need for calendar sync popup
     }
    //  } else {
       //setIsSyncPopup(false);
    //  }
   } catch (error) {
     console.error("Error fetching analytics data:", error);
     //setIsSyncPopup(false);
   }
 };
  const showPopup = (showAvailabilityPopupAfter) => {
    Swal.fire({
      title: "Sync Calendar",
      text: "Your Calendar Has Not Synced With Google Or Any EHR! Please Sync It.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sync Now",
      cancelButtonText: "Later",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle "Sync Now" action
        navigate("/providerProfile");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sessionStorage.setItem("calendarSyncPopupShown", "true");
        if (showAvailabilityPopupAfter) {
          showAvailabilityPopup();
        }
        // Handle "Later" action
        // The popup will automatically not show again until the next login since it's in a React effect
      }
    });
  };
  const showAvailabilityPopup = () => {
    Swal.fire({
      title: "Set Availability",
      text: "Your Status Is Set To Unavailable. Kindly Update Your Availability.",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Set Now",
      cancelButtonText: "Later",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle "Set Now" action
        navigate("/providerProfile");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sessionStorage.setItem("activeStatusPopupShown", "true");
      }
    });
  };
   const CheckGoogleBussiness = async () => {
     try {
      const ClinicId = localStorage.getItem("clinicId");
       const payload = {
         modelName: "clinic_google_business",
         whereCondition: {
           clinic_id: ClinicId,
         },
       };
       const response = await postData("masters/getMasterList", payload);
       if (response && response.data && response.data.length > 0) {
         setIsGoogleAccountSynced(true);
       } else {
         setIsGoogleAccountSynced(false);
       }
     } catch (error) {
       console.error("Error fetching analytics data:", error);
       setIsGoogleAccountSynced(false);
     }
   };
   const fetchClinicLocations = async () => {
     try {
       const ClinicId = localStorage.getItem("clinicId");
       const payload = {
         modelName: "clinic_locations",
         whereCondition: {
           clinic_id: ClinicId,
           is_active: 1,
           is_deleted: 0,
         },
       };

       const response = await postData("masters/getMasterList", payload);

       if (response && response.data && response.data.length > 0) {
         const locations = response.data.map((location) => ({
           label: `${location.street},${location.city}`,
           id: location.id,
         }));
         setSamaraLocations(locations);
       } else {
         setSamaraLocations([]);
       }
     } catch (error) {
       console.error("Error fetching clinic locations:", error);
       setSamaraLocations([]);
     }
   };
   const fetchgoogleLocations = async () => {
     try {
       const ClinicId = localStorage.getItem("clinicId");
       const payload = {
         modelName: "clinic_google_business_mappings",
         whereCondition: {
           clinic_id: ClinicId,
         },
       };

       const response = await postData("masters/getMasterList", payload);
       console.log("lenght.....", response.data.length);
       if (response && response.data) {
         if(response.data.length > 1){
            setIsMultiLocation(true);
         }
          const locations = response.data.map((location) => ({
            label: location.location_address_json.locality,
            id: location.id,
            clinic_location_id: location.clinic_location_id,
          }));
         setGoogleLocations(locations);
        //  setIsGoogleAccountSynced(true);
        //  fetchGoogleReviews(1);

       } else {
         setGoogleLocations([]);
       }
     } catch (error) {
       console.error("Error fetching clinic locations:", error);
       setGoogleLocations([]);
     }
   };
useEffect(() => {
  if (status) {
    handleSignUp();
  }
}, [status]);
  // const handleSignUp = async () => {
  //   try {
  //     setLoading(true); 
  //     const ClinicId = localStorage.getItem("clinicId");
  //     if (!ClinicId) {
  //       navigate("/");
  //     }
  //     const redirect_uri = `${window.location.origin}/dashboard/review`;
  //     const endpoint = `${baseUrl}/googlebusiness/auth?clinic_id=${ClinicId}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
  //      console.log("Endpoint URL:", endpoint);
  //       window.open(endpoint, "_blank");
  //      console.log("....function",status);
  //       if (status == "success") {
  //         console.log("Authentication successful:", message);
  //         //setIsModalOpen(true);
  //         fetchClinicLocations();
  //         fetchgoogleLocations();
  //       } else if (status === "error") {
  //         console.error("Authentication failed:", message);
  //         alert(`Error: ${message}`);
  //       } else {
  //         // Handle other cases or initial state
  //       }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //   } finally {
  //     setLoading(false); 
  //   }
  // };
  const handleSignUp = async () => {
    try {
      //setLoading(true);
      const ClinicId = localStorage.getItem("clinicId");
      if (!ClinicId) {
        navigate("/");
      }

      // Display SweetAlert popup
      if (status == null){
        // await Swal.fire({
        //   title:
        //     "Select the Google account associated with your Google Business Profile.",
        //   icon: "info",
        //   confirmButtonText: "OK",
        // });
        const result = await Swal.fire({
        title: "Select The Google Account Associated With Your Google Business Profile.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        console.log("User clicked OK");
        // Proceed with the authentication flow
      } else if (result.isDismissed) {
        console.log("User clicked Cancel");
        // Handle the case where the user clicks cancel
        setLoading(false);
        return;
      }
    }

      const redirect_uri = `${window.location.origin}/dashboard/review`;
      const endpoint = `${baseUrl}/googlebusiness/auth?clinic_id=${ClinicId}&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}`;
      console.log("Endpoint URL:", endpoint);
      //window.open(endpoint, "_blank");
       if (status == null){
        window.open(endpoint, "_self");
       }
      console.log("....function", status);
      if (status === "success") {
        console.log("Authentication successful:", message);
        // setIsModalOpen(true);
        fetchClinicLocations();
        fetchgoogleLocations();
      } else if (status === "error") {
        setLoading(false);
        await Swal.fire({
          title: `Error: ${message}`,
          icon: "error",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
           navigate("/dashboard") // Reload the page
          }
        });
      } else {
        // Handle other cases or initial state
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBookedAnalyticData = async (type) => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/booked-appointments?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("count", response.count);
      setBookedData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchCancelledData = async (type) => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/cancelled-appointments?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countCanc", response.count);
      setCancelledData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchRescheduleData = async (type) => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/rescheduled-appointments?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countRes", response.count);
      setRescheduleData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchConfirmedData = async (type) => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/confirmed-appointments?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countConf", response.count);
      setConfirmData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchRatingData = async () => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `googlebusiness/google-ratings?clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countRat", response.count);
      setRatingData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchReviewData = async () => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `googlebusiness/google-reviews?clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countRev", response.count);
      setReviewData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchReminderData = async () => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/reminders-via-samara`;
      const response = await getData(endpoint);
      console.log("countRem", response.count);
      setRemiderData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const fetchEHRSamaraData = async (type) => {
    try {
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/getAllBookedAppointments?type=${type.toLowerCase()}&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("countEHR", response.count);
      setEhrSamaraData(response.count);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
   const fetchActPendingData = async () => {
     try {
      const ClinicId = localStorage.getItem("clinicId");
       const endpoint = `googlebusiness/google-acts-pending?clinic_id=${ClinicId}`;
       const response = await getData(endpoint);
       console.log("countEHR", response.count);
       setActpendingData(response.count);
     } catch (error) {
       console.error("Error fetching analytics data:", error);
     }
   };

  const fetchData = async () => {
    setLoading(true); 
    try {
      const promises = [
        fetchBookedAnalyticData(selectedTab),
        fetchCancelledData(selectedTab),
        fetchRescheduleData(selectedTab),
        fetchConfirmedData(selectedTab),
        fetchRatingData(),
        fetchReviewData(),
        fetchReminderData(),
        fetchEHRSamaraData(selectedTab),
        fetchGoogleReviewRatingPercentage(selectedTab),
      ];
      await Promise.all(promises); 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTabBar) {
      fetchBarChartData(selectedTabBar);
    } else {
      setLoading(false);
    }
  }, [selectedTabBar]);

  const fetchBarChartData = async (type) => {
    try {
      setLoading(true);
      let endpoint = "";
         const ClinicId = localStorage.getItem("clinicId");
      switch (type) {
        case "Weekly":
          endpoint =
            `analytics/weekly-appointments-daywise?clinic_id=${ClinicId}`;
          break;
        case "Monthly":
          endpoint =
            `analytics/monthly-appointments-weekwise?clinic_id=${ClinicId}`;
          break;
        case "Yearly":
          endpoint =
            `analytics/yearly-appointments-monthwise?clinic_id=${ClinicId}`;
          break;
        case "All Time":
          endpoint = `analytics/yearly-appointments?clinic_id=${ClinicId}`;
          break;
        default:
          setLoading(false);
          return;
      }

      const response = await getData(endpoint);
      console.log("Data:", response);
      setBarData(response);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedLineChart) {
      fetchLineChartData(selectedLineChart);
    } else {
      setLoading(false);
    }
  }, [selectedLineChart]);

  const fetchLineChartData = async (type) => {
    try {
      setLoading(true);
      let endpoint = "";
      const ClinicId = localStorage.getItem("clinicId");
      switch (type) {
        case "Weekly":
          endpoint =
            `analytics/weekly-appointments-daywise?clinic_id=${ClinicId}`;
          break;
        case "Monthly":
          endpoint = `analytics/monthly-appointments-weekwise?clinic_id=${ClinicId}`;
          break;
        case "Yearly":
          endpoint =
            `analytics/yearly-appointments-monthwise?clinic_id=${ClinicId}`;
          break;
        case "All Time":
          endpoint = `analytics/yearly-appointments?clinic_id=${ClinicId}`;
          break;
        default:
          setLoading(false);
          return;
      }

      const response = await getData(endpoint);
      console.log("Data:", response);
      setLineData(response);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSamaraCountData = async (type) => {
    try {
      setLoading(true);
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/booked-appointments-scheduling-ratio?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("Samara Data:", response);
      setSamaraCount(response.count); 
    } catch (error) {
      console.error("Error fetching Samara data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEhrCountData = async (type) => {
    try {
      setLoading(true);
      const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `analytics/booked-appointments-scheduling-ratio?type=${type.toLowerCase()}&is_booked_via_samara=0&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("EHR Data:", response);
      setEhrCount(response.count); 
    } catch (error) {
      console.error("Error fetching EHR data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDonut) {
      fetchSamaraCountData(selectedDonut);
      fetchEhrCountData(selectedDonut);
    } else {
      setLoading(false);
    }
  }, [selectedDonut]);

  const handleReadMoreClick = (reviewId) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId],
    }));
  };
  const categories = [
    {
      label: "All",
      count: ehrsamaraData + actPending,
      color: "gray",
      path: "/dashboard",
    },
    {
      label: "Scheduling",
      count: ehrsamaraData,
      color: "red",
      path: `/ClinicBookingHistory/${"All Bookings"}`,
    },
    {
      label: "Patient Satisfaction",
      count: actPending,
      color: "orange",
      path: `/dashboard/${"review"}`,
    },
  ];
  const fetchReviews = async (type) => {
    try {
      //setLoading(true);
       const ClinicId = localStorage.getItem("clinicId");
      const endpoint = `googlebusiness/getAllReviews?is_rating_from=${type}&clinic_id=${ClinicId}`;
      const response = await getData(endpoint);
      console.log("Reviews Data...:", response);

      // const transformedData = response.map((review) => ({
      //   id: review.id,
      //   platform: review.is_rating_from === 1 ? "google" : "samara",
      //   profilePic: review.profilephotourl,
      //   name: review.reviewername,
      //   rating:
      //     review.rating === "FIVE"
      //       ? 5
      //       : review.rating === "FOUR"
      //       ? 4
      //       : review.rating === "THREE"
      //       ? 3
      //       : review.rating === "TWO"
      //       ? 2
      //       : 1,
      //   text: review.comment || "",
      //   date: review.reviewtime,
      //   remark: review.acts,
      //   is_rating_from: review.is_rating_from,
      //   actFrom: review.acts_from,
      //   //location: review.clinic_google_business_mappings.location_address_json.locality,
      //   location:
      //     review.is_rating_from === 1
      //       ? review?.clinic_google_business_mappings?.location_address_json
      //           ?.locality
      //       : review?.clinicLocations?.clinic_locations?.street,
      // }));
       const transformedData = response.map((review) => {
         let name; // Default to reviewer name

         // Determine the name based on acts_from value
         if (review?.acts_from === 1 && review?.clinic_details) {
           name = review?.clinic_details?.name || name;
         } else if (review?.acts_from === 2 && review?.practitioner_details) {
           const { f_name, l_name } = review?.practitioner_details;
           name = `${f_name || ""} ${l_name || ""}`.trim() || name;
         } else if (review?.acts_from === 3 && review?.assistant_details) {
           const { f_name, l_name } = review?.assistant_details;
           name = `${f_name || ""} ${l_name || ""}`.trim() || name;
         }
         return {
           id: review.id,
           platform: review.is_rating_from === 1 ? "google" : "samara",
           profilePic: review.profilephotourl,
           actname: name,
           name: review.reviewername,
           rating:
             review.rating === "FIVE"
               ? 5
               : review.rating === "FOUR"
               ? 4
               : review.rating === "THREE"
               ? 3
               : review.rating === "TWO"
               ? 2
               : 1,
           text: review.comment || "",
           date: review.reviewtime,
           remark: review.acts,
           is_rating_from: review.is_rating_from,
           actFrom: review.acts_from,
           location:
             review.is_rating_from === 1
               ? review?.clinic_google_business_mappings?.location_address_json
                   ?.locality
               : review?.clinicLocations?.clinic_locations?.street,
         };
       });

      const counts = transformedData.reduce(
        (acc, review) => {
          if (review.platform === "google") {
            acc.google += 1;
          } else if (review.platform === "samara") {
            acc.samara += 1;
          }
          return acc;
        },
        { google: 0, samara: 0 }
      );

      if (type === 1) {
        setGoogleReviewsData(transformedData);
        setGoogleReviewCounts(counts.google);
      } else {
        setSamaraReviewData(transformedData);
        console.log("transformedData", transformedData);
        setSamaraCounts(counts.samara);
      }
    } catch (error) {
      console.error("Error fetching review data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGoogleReviews = () => fetchReviews(1);
  const fetchSamaraReviews = () => fetchReviews(0);
  useEffect(() => {
    fetchGoogleReviews(1);
    fetchSamaraReviews(0);
    fetchActPendingData();
    fetchAverageRating();
    fetchAverageRatingSamara();
  }, []);
   const fetchAverageRating = async () => {
     try {
       const ClinicId = localStorage.getItem("clinicId");
       const endpoint = `googlebusiness/google-average-rating?clinic_id=${ClinicId}&is_rating_from=1`;
       const response = await getData(endpoint);
       console.log("averageRatingGoogle", response.averageRating);
       setAverageRatingGoogle(response.averageRating);
     } catch (error) {
       console.error("Error fetching analytics data:", error);
     }
   };
    const fetchAverageRatingSamara = async () => {
      try {
        const ClinicId = localStorage.getItem("clinicId");
        const endpoint = `googlebusiness/google-average-rating?clinic_id=${ClinicId}&is_rating_from=0`;
        const response = await getData(endpoint);
        console.log("averageRatingGoogle", response.averageRating);
        setAverageRatingSamara(response.averageRating);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };
    const fetchGoogleReviewRatingPercentage = async (type) => {
      try {
        const ClinicId = localStorage.getItem("clinicId");
        const endpoint = `googlebusiness/google-review-rating-percentage?type=${type.toLowerCase()}&clinic_id=${ClinicId}`;
        const response = await getData(endpoint);
        setReviewRatingPercent(response.percentageChange);
        setCurrentRatingCount(response.currentCount);
        setLastRatingCount(response.previousCount);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };
  const [reviews, setReviews] = useState([
    {
      id: 1,
      name: "Racky Roberts",
      date: "February 11",
      text: "Hiring Feedback Wrench has been one of the greatest investments I have made in my practice.",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile1.jpg",
      rating: 5,
    },
    {
      id: 2,
      name: "Rita Nwokeji",
      date: "March 11, 2021",
      text: "Rob helped me identify the next steps for my accounting practice quickly. Thanks a lot!",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile2.jpg",
      rating: 4,
    },
    {
      id: 3,
      name: "Mike Stilwell",
      date: "March 11, 2018",
      text: "Met with Rob as he helped me navigate some long-term strategies as I operated my business.",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile3.jpg",
      rating: 5,
    },
    {
      id: 4,
      name: "Racquelle Roberts",
      date: "February 11",
      text: "Hiring Feedback Wrench has been one of the greatest investments I have made in my practice.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile4.jpg",
      rating: 5,
    },
    {
      id: 5,
      name: "Mahesh Nwokeji",
      date: "March 11, 2021",
      text: "Rob helped me identify the next steps for my accounting practice quickly.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile5.jpg",
      rating: 4,
    },
    {
      id: 6,
      name: "Akshay Stilwell",
      date: "March 11, 2018",
      text: "Met with Rob as he helped me navigate some long-term strategies as I operated my business.Met with Rob as he helped me navigate some long-term strategies as I operated my business.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile6.jpg",
      rating: 5,
    },
    {
      id: 7,
      name: "Racky Roberts",
      date: "February 11",
      text: "Hiring Feedback Wrench has been one of the greatest investments I have made in my practice.",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile1.jpg",
      rating: 5,
    },
    {
      id: 8,
      name: "Rita Nwokeji",
      date: "March 11, 2021",
      text: "Rob helped me identify the next steps for my accounting practice quickly. Thanks a lot!",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile2.jpg",
      rating: 4,
    },
    {
      id: 9,
      name: "Mike Stilwell",
      date: "March 11, 2018",
      text: "Met with Rob as he helped me navigate some long-term strategies as I operated my business.",
      platform: "google",
      remark: "",
      profilePic: "path/to/profile3.jpg",
      rating: 5,
    },
    {
      id: 10,
      name: "Racquelle Roberts",
      date: "February 11",
      text: "Hiring Feedback Wrench has been one of the greatest investments I have made in my practice.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile4.jpg",
      rating: 5,
    },
    {
      id: 11,
      name: "Mahesh Nwokeji",
      date: "March 11, 2021",
      text: "Rob helped me identify the next steps for my accounting practice quickly.Rob helped me identify the next steps for my accounting practice quickly.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile5.jpg",
      rating: 4,
    },
    {
      id: 12,
      name: "Akshay Stilwell",
      date: "March 11, 2018",
      text: "Met with Rob as he helped me navigate some long-term strategies as I operated my business.Met with Rob as he helped me navigate some long-term strategies as I operated my business.Met with Rob as he helped me navigate some long-term strategies as I operated my business.",
      platform: "samara",
      remark: "",
      profilePic: "path/to/profile6.jpg",
      rating: 5,
    },
  ]);
  const handleActClick = async (reviewId, isRatingFromOne) => {
    let remark;
    if (isRatingFromOne) {
      remark = "viewed";
    } else {
      const { value: userRemark } = await Swal.fire({
        title: "Describe Action Taken To Improve The Patient Experience",
        input: "textarea",
        inputPlaceholder: "Enter Your Act",
        showCancelButton: true,
        confirmButtonText: "Submit",
      });
      remark = userRemark;
    }

    if (remark) {
      try {
        if (!isRatingFromOne) {
        setLoading(true);
        }
        const accountType = localStorage.getItem("accountType");
        const refernceId = localStorage.getItem("refernceId");
        let actsFrom;
        switch (accountType) {
          case "Clinic":
            actsFrom = 1;
            break;
          case "Practitioners":
            actsFrom = 2;
            break;
          case "Assistances":
            actsFrom = 3;
            break;
          default:
            actsFrom = 0; // or handle the default case appropriately
        }
        const payload = {
          modelName: "patient_appointment_ratings",
          id: reviewId,
          inputData: {
            acts: remark,
            acts_from: actsFrom,
            acts_by: refernceId,
          },
        };
        console.log("payload act...", payload);
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );

        if (response.code === 1) {
          setLoading(false);
          //Swal.fire("Success", "Your Act Has Been Added!", "success");
           if (!isRatingFromOne) {
             Swal.fire("Success", "Your Act Has Been Added!", "success");
           }
          fetchReviews(1);
          fetchReviews(0);
          fetchActPendingData();
          setGoogleReviewsData((prevReviews) =>
            prevReviews.map((review) =>
              review.id === reviewId ? { ...review, remark } : review
            )
          );
          setSamaraReviewData((prevReviews) =>
            prevReviews.map((review) =>
              review.id === reviewId ? { ...review, remark } : review
            )
          );
        } else {
          Swal.fire(
            "Error",
            "Failed To Add Your Act. Please Try Again.",
            "error"
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error adding act:", error);
        Swal.fire("Error", "An error occurred. Please try again.", "error");
        setLoading(false);
      }
    }
  };
 const getActFromLabel = (actFrom) => {
  console.log("actform",actFrom)
   switch (actFrom) {
     case 1:
       return "Clinic";
     case 2:
       return "Practitioner";
     case 3:
       return "Assistance";
     default:
       return null;
   }
 };

 const handleMouseEnter = (el,name, actFrom, remark) => {
   console.log("actform", actFrom);
  //  const actFromLabel = getActFromLabel(actFrom);
  //  const content = `
  //   <div>
  //     <strong>Act From:</strong> ${actFromLabel}<br/>
  //     <strong>Name:</strong>${name}<br/>
  //     <strong>Act:</strong> ${remark}
  //   </div>
  // `;
const actFromLabel = getActFromLabel(actFrom);

let content = "<div>";

// if (actFromLabel) {
//   content += `<strong>Act From:</strong> ${actFromLabel}<br/>`;
// }

if (name) {
  content += `<strong>Name:</strong> ${name}<br/>`;
}

content += `<strong>Action:</strong> ${remark}</div>`;

   tippy(el, {
     content: content,
     allowHTML: true,
     interactive: true,
   }).show();
 };

  const handleMouseLeave = (el) => {
    tippy(el).destroy();
  };
  const handleCategoryClick = (path) => {
    navigate(path);
  };
   const fetchPercentageCountData = async (type) => {
     try {
      const ClinicId = localStorage.getItem("clinicId");
       const endpoint = `analytics/getBookedAppointmentsPercentage?type=${type.toLowerCase()}&is_booked_via_samara=1&clinic_id=${ClinicId}`;
       const response = await getData(endpoint);
       console.log("countPerce...", response);
       setLastweekCount(response.BookedPercetage.previousCountBooked);
       setCurrentweekCount(response.BookedPercetage.currentCountBooked);
       setPercentChange(response.BookedPercetage.percentageChangeBooked);
       setLastweekCancel(response.CancelledPercetage.previousCountCancelled);
       setCancelCurrent(response.CancelledPercetage.currentCountCancelled);
       setCancelPercent(response.CancelledPercetage.percentageChangeCancelled);
       setLastweekReschedule(response.RescheduledPercetage.previousCountRescheduled)
       setRescheduleCurrent(response.RescheduledPercetage.currentCountRescheduled);
       setReschedulePercent(response.RescheduledPercetage.percentageChangeRescheduled)
       setLastweekConfirm(response.ConfirmedPercetage.previousCountConfirmed);
       setConfirmCurrent(response.ConfirmedPercetage.currentCountConfirmed);
       setConfirmPercent(response.ConfirmedPercetage.percentageChangeConfirmed);
     } catch (error) {
       console.error("Error fetching analytics data:", error);
     }
   };
   useEffect(() => {
     if (selectedTab) {
       fetchPercentageCountData(selectedTab);
     } else {
       setLoading(false);
     }
   }, [selectedTab]);
   const handleEditClick = ()=>{
    fetchgoogleLocations();
    setIsModalOpen(true);
   }
 

 const countDifference = currentWeekCount - lastWeekCount;
 const cancelDiff = cancelCurrent - lastWeekCancel;
 const rescheduleDiff = rescheduleCurrent - lastWeekReschdule;
 const confirmDiff = confirmCurrent - lastWeekConfirm;
 const reviewRatingDiff = CurrentReviewRating - lastReviewRating;
  const percentageColorClass = percentageChange < 0 ? "text-red-500" : "text-green-500";
   const parsedcancelPercent = parseFloat(cancelPercent.replace("%", ""));
  const cancelPercentColorClass = parsedcancelPercent < 0 ? "text-red-500" : "text-green-500";
   const parsedReschedulePercent = parseFloat(reschedulePercent.replace("%", ""));
   const reschedulePercentColorClass = parsedReschedulePercent < 0 ? "text-red-500" : "text-green-500";
   const parsedConfirmPercent = parseFloat(confirmPercent.replace('%', ''));
   const confirmPercentColorClass = parsedConfirmPercent < 0 ? "text-red-500" : "text-green-500";
   const parsedReviewRatingPercent = parseFloat(ReviewRatingPercent.replace('%', ''));
   const reviewRatingPercentColorClass = parsedReviewRatingPercent < 0 ? "text-red-500" : "text-green-500";
  return (
    <>
      <Header />
      <div className="pt-16">
        <div className="w-full mx-auto px-4 sm:px-6 mt-6 lg:px-8">
          {/* Tabs Component */}
          <Tabs tabs={tabs}>
            <div id="home">
              {loading && <Loader2 />}
              <>
                <div className="flex justify-center bg-gray-700 text-white py-2 px-4 mb-4 rounded-md lg:w-1/3 mx-auto">
                  {categories.map((category) => (
                    <div
                      key={category.label}
                      className="flex flex-col items-center mx-2"
                      onClick={() => handleCategoryClick(category.path)}
                    >
                      <div>
                        <span
                          className={`w-3 h-3 rounded-full inline-block mr-2`}
                          style={{ backgroundColor: category.color }}
                        ></span>
                        <span className="font-semibold cursor-pointer">
                          {category.label}
                        </span>
                      </div>
                      <div>
                        <span>{category.count}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center">
                  <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                    {["All Time", "Weekly", "Monthly", "Yearly"].map((tab) => (
                      <li key={tab} className="me-2">
                        <button
                          onClick={() => setSelectedTab(tab)}
                          className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                            selectedTab === tab
                              ? "bg-blue-500 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                              : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                          }`}
                        >
                          {tab}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="container mx-auto pb-4">
                  <div className="flex flex-wrap justify-center gap-4 md:gap-6 xl:gap-8 mt-4 border border-gray-300 rounded-lg p-4">
                    <div className="flex flex-wrap justify-center gap-4 md:gap-6 xl:gap-8">
                      <CardStates
                        title="Booked Via Samara"
                        color="rgba(0, 250, 0, 0.1)"
                      >
                        <div className="flex flex-col">
                          {selectedTab !== "All Time" && (
                            <div
                              className={`inline-block rounded px-2 py-1 mb-2 font-bold ${percentageColorClass}`}
                            >
                              {countDifference}({percentageChange})
                            </div>
                          )}
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {bookedData}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                      <CardStates
                        title="Confirmed Via Samara"
                        color="rgba(250, 0, 0, 0.1)"
                      >
                        <div className="flex flex-col">
                          {selectedTab !== "All Time" && (
                            <div
                              className={`inline-block rounded px-2 py-1 mb-2 font-bold ${confirmPercentColorClass}`}
                            >
                              {confirmDiff}({confirmPercent})
                            </div>
                          )}
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {confirmData}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                      <CardStates title="Reminders Via Samara(Bookings)">
                        <svg
                          className="fill-primary dark:fill-white"
                          width="70"
                          height="50"
                          viewBox="0 0 60 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fontSize="24"
                            fontWeight="bold"
                            fill="currentColor"
                          >
                            {remidersData}
                          </text>
                        </svg>
                      </CardStates>
                      <CardStates
                        title="Patient Acquisition"
                        color="rgba(255, 192, 203, 0.1)"
                      >
                        <div className="flex flex-col">
                          {selectedTab !== "All Time" && (
                            <div
                              className={`inline-block rounded px-2 py-1 mb-2 font-bold ${cancelPercentColorClass}`}
                            >
                              {cancelDiff}({cancelPercent})
                            </div>
                          )}
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {cancelledData}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                    </div>
                    <div className="flex flex-wrap justify-center gap-4 md:gap-6 xl:gap-8">
                      <CardStates
                        title="Google Reviews"
                        color="rgba(251, 188, 5, 0.1)"
                      >
                        <div className="flex flex-col">
                          {selectedTab !== "All Time" && (
                            <div
                              className={`inline-block rounded px-2 py-1 mb-2 font-bold ${reviewRatingPercentColorClass}`}
                            >
                              {reviewRatingDiff}({ReviewRatingPercent})
                            </div>
                          )}
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {reviewData}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                      <CardStates
                        title="Google Rating"
                        color="rgba(0, 128, 128, 0.1)"
                      >
                        <div className="flex flex-col">
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {googleAvgRating.toFixed(1)}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                      <CardStates
                        title="Total Bookings (Samara + EHR)"
                        color="rgba(128, 0, 128, 0.1)"
                      >
                        <svg
                          className="fill-primary dark:fill-white"
                          width="70"
                          height="50"
                          viewBox="0 0 60 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fontSize="24"
                            fontWeight="bold"
                            fill="currentColor"
                          >
                            {ehrsamaraData}
                          </text>
                        </svg>
                      </CardStates>
                      <CardStates
                        title="Re-Bookings"
                        color="rgba(33, 103, 250, 0.1)"
                      >
                        <div className="flex flex-col">
                          {selectedTab !== "All Time" && (
                            <div
                              className={`inline-block rounded px-2 py-1 mb-2 font-bold ${reschedulePercentColorClass}`}
                            >
                              {rescheduleDiff}({reschedulePercent})
                            </div>
                          )}
                          <svg
                            className="fill-primary dark:fill-white"
                            width="70"
                            height="50"
                            viewBox="0 0 60 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="24"
                              fontWeight="bold"
                              fill="currentColor"
                            >
                              {rescheduleData}
                            </text>
                          </svg>
                        </div>
                      </CardStates>
                    </div>
                  </div>
                </div>
              </>
            </div>

            <div id="scheduling">
              {loading && <Loader2 />}
              <div className="flex justify-center items-center mb-4">
                <h2 className="text-2xl font-bold text-center">
                  Practice Management: Insights & Analytics
                </h2>
              </div>
              <div className="relative border border-gray-300 rounded-lg p-4">
                <div className="flex justify-end gap-4 mt-4">
                  <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                    {["All Time", "Weekly", "Monthly", "Yearly"].map((tab) => (
                      <li key={tab} className="me-2">
                        <button
                          onClick={() => setSelectedTabBar(tab)}
                          className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                            selectedTabBar === tab
                              ? "bg-blue-500 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                              : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                          }`}
                        >
                          {tab}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="container mx-auto mt-4">
                  <BarChart data={barData} />
                </div>
              </div>
              <div className="container mx-auto mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="relative border border-gray-300 rounded-lg p-4">
                  <div className="absolute top-0 right-0 mt-2 mr-2 flex gap-2">
                    <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                      {["All Time", "Weekly", "Monthly", "Yearly"].map(
                        (tab) => (
                          <li key={tab} className="me-2">
                            <button
                              onClick={() => setSelectedDonut(tab)}
                              className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                                selectedDonut === tab
                                  ? "bg-blue-500 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                                  : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                              }`}
                            >
                              {tab}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="mt-12">
                    <DonutChart data={[samaraCount, ehrCount]} />
                  </div>
                </div>
                <div className="relative border border-gray-300 rounded-lg p-4">
                  <div className="absolute top-0 left-0 mt-2 mr-2 flex gap-2 p-2">
                    <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                      {["All Time", "Weekly", "Monthly", "Yearly"].map(
                        (tab) => (
                          <li key={tab} className="me-2">
                            <button
                              onClick={() => setSelectedLineChart(tab)}
                              className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                                selectedLineChart === tab
                                  ? "bg-blue-500 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                                  : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                              }`}
                            >
                              {tab}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="mt-12">
                    <LineChart data={lineData} />
                  </div>
                </div>
              </div>
            </div>

            <div id="review">
              {loading && <Loader2 />}
              <div className="flex justify-center items-center mb-4">
                <h2 className="text-2xl font-bold text-center">
                  Patient Satisfaction: Insights & Analytics
                </h2>
              </div>
              <div className="container mx-auto mt-4">
                {/* <div className="text-center">
                  <h2 className="text-5xl font-bold">The Trust We've Earned</h2>
                  <p className="text-3xl text-gray-600">
                    Feedback from Google Reviews
                  </p>
                </div> */}
                <>
                  <div className="flex flex-nowrap justify-center gap-4 mt-4">
                    {isGoogleAccountSynced ? (
                      <button
                        onClick={() => {
                          setSelectedReviewType("google");
                        }}
                        className={`flex items-center gap-2 px-4 py-2 sm:px-6 sm:py-3 border-4 rounded-lg w-64 ${
                          selectedReviewType === "google"
                            ? "border-blue-500"
                            : "border-gray-200"
                        } text-base sm:text-lg shadow-lg transition duration-200 ease-in-out transform hover:scale-105`}
                      >
                        <div>
                          <div>Google Reviews</div>
                          <div className="flex flex-col items-start mt-1">
                            <img
                              src="/google-logo-transparent.png"
                              alt="Google Logo"
                              className="h-6"
                            />
                            <div className="flex flex-row items-center mt-2">
                              <span className="text-lg sm:text-xl font-bold">
                                {/* {Math.round(googleAvgRating || 0)} */}
                                {googleAvgRating
                                  ? googleAvgRating.toFixed(1)
                                  : "0.0"}
                              </span>
                              <span className="flex ml-2">
                                {[...Array(5)].map((_, i) => (
                                  <div key={i} className="relative">
                                    <svg
                                      className={`w-3 sm:w-4 h-3 sm:h-4 ${
                                        i < Math.floor(googleAvgRating || 0)
                                          ? "text-yellow-400"
                                          : "text-gray-400"
                                      }`}
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.953a1 1 0 00.95.69h4.184c.969 0 1.371 1.24.588 1.81l-3.396 2.47a1 1 0 00-.364 1.118l1.286 3.953c.3.921-.755 1.688-1.54 1.118l-3.396-2.47a1 1 0 00-1.176 0l-3.396 2.47c-.784.57-1.839-.197-1.54-1.118l1.286-3.953a1 1 0 00-.364-1.118l-3.396-2.47c-.784-.57-.381-1.81.588-1.81h4.184a1 1 0 00.95-.69l1.286-3.953z" />
                                    </svg>
                                    {googleAvgRating - i > 0 &&
                                      googleAvgRating - i < 1 && (
                                        <svg
                                          className="w-3 sm:w-4 h-3 sm:h-4 text-yellow-400 absolute top-0 left-0"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          style={{
                                            clipPath:
                                              "polygon(0 0, 50% 0, 50% 100%, 0% 100%)",
                                          }}
                                        >
                                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.953a1 1 0 00.95.69h4.184c.969 0 1.371 1.24.588 1.81l-3.396 2.47a1 1 0 00-.364 1.118l1.286 3.953c.3.921-.755 1.688-1.54 1.118l-3.396-2.47a1 1 0 00-1.176 0l-3.396 2.47c-.784.57-1.839-.197-1.54-1.118l1.286-3.953a1 1 0 00-.364-1.118l-3.396-2.47c-.784-.57-.381-1.81.588-1.81h4.184a1 1 0 00.95-.69l1.286-3.953z" />
                                        </svg>
                                      )}
                                  </div>
                                ))}
                              </span>
                              <span className="ml-2 text-sm sm:text-base">
                                {googlereviewCount} reviews
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <div className="flex flex-col items-center bg-white py-2 px-4 sm:py-3 sm:px-6 border-4 rounded-lg w-64 shadow-md">
                        <p className="text-xl font-semibold text-gray-800 text-center">
                          Link Your Google Business Account To See Google
                          Reviews.
                        </p>
                        <button
                          className="mt-2 px-4 py-2 text-sm sm:px-6 sm:py-3 sm:text-base bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-full shadow-lg hover:from-blue-600 hover:to-blue-700 transition-all duration-300 transform hover:scale-105"
                          onClick={handleSignUp}
                        >
                          {/* Sign Up */}
                          Link
                        </button>
                        <PopupModal
                          isOpen={isModalOpen}
                          onClose={() => setIsModalOpen(false)}
                          googleLocations={googleLocations}
                          samaraLocations={samaraLocations}
                          onSubmit={handleModalSubmit}
                        />
                      </div>
                    )}
                    <button
                      onClick={() => {
                        setSelectedReviewType("samara");
                      }}
                      className={`flex items-center gap-2 px-4 py-2 sm:px-6 sm:py-3 border-4 rounded-lg w-64 ${
                        selectedReviewType === "samara"
                          ? "border-blue-500"
                          : "border-gray-200"
                      } text-base sm:text-lg shadow-lg transition duration-200 ease-in-out transform hover:scale-105`}
                    >
                      <div>
                        <div>Learn & Act With Samara Well</div>
                        <div className="flex flex-col items-start mt-1">
                          <img
                            src="/samara-logo.png"
                            alt="Samara Logo"
                            className="h-6"
                          />
                          <div className="flex flex-row items-center mt-2">
                            <span className="text-lg sm:text-xl font-bold">
                              {/* {Math.round(samaraAvgRating || 0)} */}
                              {samaraAvgRating
                                ? samaraAvgRating.toFixed(1)
                                : "0.0"}
                            </span>
                            <span className="flex ml-2">
                              {[...Array(5)].map((_, i) => (
                                <div key={i} className="relative">
                                  <svg
                                    className={`w-3 sm:w-4 h-3 sm:h-4 ${
                                      i < Math.floor(samaraAvgRating || 0)
                                        ? "text-yellow-400"
                                        : "text-gray-400"
                                    }`}
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.953a1 1 0 00.95.69h4.184c.969 0 1.371 1.24.588 1.81l-3.396 2.47a1 1 0 00-.364 1.118l1.286 3.953c.3.921-.755 1.688-1.54 1.118l-3.396-2.47a1 1 0 00-1.176 0l-3.396 2.47c-.784.57-1.839-.197-1.54-1.118l1.286-3.953a1 1 0 00-.364-1.118l-3.396-2.47c-.784-.57-.381-1.81.588-1.81h4.184a1 1 0 00.95-.69l1.286-3.953z" />
                                  </svg>
                                  {samaraAvgRating - i > 0 &&
                                    samaraAvgRating - i < 1 && (
                                      <svg
                                        className="w-3 sm:w-4 h-3 sm:h-4 text-yellow-400 absolute top-0 left-0"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        style={{
                                          clipPath:
                                            "polygon(0 0, 50% 0, 50% 100%, 0% 100%)",
                                        }}
                                      >
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.953a1 1 0 00.95.69h4.184c.969 0 1.371 1.24.588 1.81l-3.396 2.47a1 1 0 00-.364 1.118l1.286 3.953c.3.921-.755 1.688-1.54 1.118l-3.396-2.47a1 1 0 00-1.176 0l-3.396 2.47c-.784.57-1.839-.197-1.54-1.118l1.286-3.953a1 1 0 00-.364-1.118l-3.396-2.47c-.784-.57-.381-1.81.588-1.81h4.184a1 1 0 00.95-.69l1.286-3.953z" />
                                      </svg>
                                    )}
                                </div>
                              ))}
                            </span>
                            <span className="ml-2 text-sm sm:text-base">
                              {samaraReviewCount} reviews
                            </span>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>

                  <div className="relative border border-gray-300 rounded-lg p-4 mt-4">
                    <div className="flex justify-between items-center">
                      <div className="flex-1 text-center">
                        <h2 className="text-2xl font-semibold mt-4 inline-block">
                          {selectedReviewType === "google"
                            ? "Google Reviews"
                            : "Learn & Act With Samara Well"}
                        </h2>
                        {/* {selectedReviewType === "google" && (
                          <button
                            onClick={handleSyncClick}
                            className="ml-4 text-blue-500 hover:text-blue-700 transition-colors duration-200 inline-flex items-center"
                          >
                            Sync
                            <i className="fas fa-sync text-xl md:text-2xl ml-2"></i>
                          </button>
                        )} */}
                      </div>
                      {selectedReviewType === "google" &&
                        isGoogleAccountSynced &&
                        IsMultiLocation && (
                          <button
                            onClick={handleEditClick}
                            title="Edit Location Mappings"
                            className="ml-2 text-blue-500 hover:text-blue-700 transition-colors duration-200"
                          >
                            <i className="fas fa-edit text-xl md:text-2xl"></i>{" "}
                            {/* FontAwesome edit icon */}
                          </button>
                        )}
                    </div>
                    {isGoogleAccountSynced && (
                      <div className="flex flex-wrap justify-center gap-6 mt-4">
                        {googleReviewsData
                          .filter(
                            (review) => review.platform === selectedReviewType
                          )
                          .map((review) => (
                            <div
                              key={review.id}
                              className="bg-white p-4 rounded shadow-md w-64 mb-2 flex flex-col justify-between"
                              onClick={() => {
                                if (!review.remark) {
                                  handleActClick(
                                    review.id,
                                    review.is_rating_from === 1
                                  );
                                }
                              }}
                            >
                              <div>
                                <div className="flex items-center">
                                  <img
                                    //src={review.profilePic}
                                    // src={`${review.profilePic}`}
                                    src={
                                      review.is_rating_from === 1
                                        ? `${review.profilePic}`
                                        : `${apiUrl}/${review.profilePic}`
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "/placeholder.png";
                                    }}
                                    alt="Profile"
                                    className="w-16 h-16 rounded-full"
                                  />
                                  <div className="ml-4">
                                    <p className="text-lg font-semibold">
                                      {review.name}
                                    </p>
                                    <div className="flex items-center">
                                      <span className="text-yellow-500">
                                        {"★".repeat(review.rating)}
                                      </span>
                                      <span className="text-gray-400">
                                        {"★".repeat(5 - review.rating)}
                                      </span>
                                      {review.date !== "Invalid date" && (
                                        <p className="text-sm text-gray-500">
                                          {review.date}
                                        </p>
                                      )}
                                    </div>
                                    {review?.location && (
                                      <p className="text-sm text-gray-500 mt-1">
                                        {review?.location}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <p className="mt-2 text-gray-800">
                                  {expandedReviews[review.id] ||
                                  review.text.length <= 80
                                    ? review.text
                                    : `${review.text.substring(0, 80)}...`}
                                  {review.text.length > 80 && (
                                    <span
                                      className="text-blue-500 cursor-pointer"
                                      onClick={() =>
                                        handleReadMoreClick(review.id)
                                      }
                                    >
                                      {expandedReviews[review.id]
                                        ? " Show less"
                                        : " Read more"}
                                    </span>
                                  )}
                                </p>
                              </div>
                              <div className="flex justify-between items-center mt-auto">
                                <div className="flex items-center">
                                  <img
                                    src={
                                      review.platform === "google"
                                        ? "/icons8-google-48.png"
                                        : "/PNGonlylogo.ico"
                                    }
                                    alt={
                                      review.platform === "google"
                                        ? "Google"
                                        : "Samara Well"
                                    }
                                    className="w-7 h-7 mr-2"
                                  />
                                  <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">
                                      Posted on
                                    </span>
                                    <span className="text-sm font-semibold">
                                      {review.platform === "google"
                                        ? "Google"
                                        : "Samara Well"}
                                    </span>
                                  </div>
                                </div>
                                {/* {review.remark ? (
                                  <button
                                    className="px-3 py-1 rounded relative"
                                    onMouseEnter={(e) =>
                                      handleMouseEnter(
                                        e.currentTarget,
                                        review.actname,
                                        review.actFrom,
                                        review.remark
                                      )
                                    }
                                    onMouseLeave={(e) =>
                                      handleMouseLeave(e.currentTarget)
                                    }
                                  >
                                    <i className="fas fa-eye text-black-500"></i>
                                  </button>
                                ) : review.remark === null ? ( // Check if review.acts is null
                                  <button
                                    className="bg-blue-500 text-white px-3 py-1 rounded"
                                    onClick={() => handleActClick(review.id)}
                                  >
                                    Act
                                  </button>
                                ) : null} */}
                                {!review.remark && (
                                  <img
                                    src="/new-product_5681843.png" // Replace with the path to your icon
                                    alt="Action Icon"
                                    className="w-9 h-9 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents triggering the onClick of the entire block
                                      handleActClick(
                                        review.id,
                                        review.is_rating_from === 1
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    <div className="flex flex-wrap justify-center gap-6 mt-4">
                      {samaraReviewData
                        .filter(
                          (review) => review.platform === selectedReviewType
                        )
                        .map((review) => (
                          <div
                            key={review.id}
                            className="bg-white p-4 rounded shadow-md w-64 mb-2 flex flex-col justify-between"
                          >
                            <div>
                              <div className="flex items-center">
                                <img
                                  //src={review.profilePic}
                                  // src={`${review.profilePic}`}
                                  src={
                                    review.is_rating_from === 1
                                      ? `${review.profilePic}`
                                      : `${apiUrl}/${review.profilePic}`
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/placeholder.png";
                                  }}
                                  alt="Profile"
                                  className="w-16 h-16 rounded-full"
                                />
                                <div className="ml-4">
                                  <p className="text-lg font-semibold">
                                    {review.name}
                                  </p>
                                  <div className="flex items-center">
                                    <span className="text-yellow-500">
                                      {"★".repeat(review.rating)}
                                    </span>
                                    <span className="text-gray-400">
                                      {"★".repeat(5 - review.rating)}
                                    </span>
                                    {review.date !== "Invalid date" && (
                                      <p className="text-sm text-gray-500">
                                        {review.date}
                                      </p>
                                    )}
                                  </div>
                                  {review?.location && (
                                    <p className="text-sm text-gray-500 mt-1">
                                      {review?.location}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <p className="mt-2 text-gray-800">
                                {expandedReviews[review.id] ||
                                review.text.length <= 80
                                  ? review.text
                                  : `${review.text.substring(0, 80)}...`}
                                {review.text.length > 80 && (
                                  <span
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() =>
                                      handleReadMoreClick(review.id)
                                    }
                                  >
                                    {expandedReviews[review.id]
                                      ? " Show less"
                                      : " Read more"}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="flex justify-between items-center mt-auto">
                              <div className="flex items-center">
                                <img
                                  src={
                                    review.platform === "google"
                                      ? "/icons8-google-48.png"
                                      : "/PNGonlylogo.ico"
                                  }
                                  alt={
                                    review.platform === "google"
                                      ? "Google"
                                      : "Samara Well"
                                  }
                                  className="w-7 h-7 mr-2"
                                />
                                <div className="flex flex-col">
                                  <span className="text-sm text-gray-500">
                                    Posted on
                                  </span>
                                  <span className="text-sm font-semibold">
                                    {review.platform === "google"
                                      ? "Google"
                                      : "Samara Well"}
                                  </span>
                                </div>
                              </div>
                              {review.remark ? (
                                <button
                                  className="px-3 py-1 rounded relative"
                                  onMouseEnter={(e) =>
                                    handleMouseEnter(
                                      e.currentTarget,
                                      review.actname,
                                      review.actFrom,
                                      review.remark
                                    )
                                  }
                                  onMouseLeave={(e) =>
                                    handleMouseLeave(e.currentTarget)
                                  }
                                >
                                  <i className="fas fa-eye text-black-500"></i>
                                </button>
                              ) : review.remark === null ? ( // Check if review.acts is null
                                <button
                                  className="bg-blue-500 text-white px-3 py-1 rounded"
                                  onClick={() => handleActClick(review.id)}
                                >
                                  Act
                                </button>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
                {isModalOpen && (
                  <PopupModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    googleLocations={googleLocations}
                    samaraLocations={samaraLocations}
                    onSubmit={handleModalSubmit}
                  />
                )}
              </div>
            </div>
          </Tabs>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReviewsAnalyticsPage;
