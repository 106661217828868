var env = "testing";
export var apiUrl =
  env === "testing" ? "https://samarawellapi.disctesting.in" : ""; //testing
//export var apiUrl = env === 'testing' ? 'http://localhost:6001' : '';//local
// export var apiUrl = env === 'testing' ? 'https://samarastageapi.disctesting.in' : '';//stagging
// export var apiUrl = env === 'testing' ? 'https://api.samarawell.com' : '';// Production
//export var apiUrl = env === 'testing' ? 'https://samarastageapi.disctesting.in' : '';

export var baseUrl = env === "testing" ? `${apiUrl}/api/` : "";

// var env = 'testing';
// export var apiUrl = ""
// if (env == 'testing') {
//   apiUrl = 'https://samarawellapi.disctesting.in';
// } else {
//   apiUrl = '';
// }

// var baseUrl = '';
// if (env == 'testing') {
//   baseUrl = 'https://samarawellapi.disctesting.in/api/';
// } else {
//   baseUrl = '';
// }

export const postData = async (apiName, data) => {
  var options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // Ensure data is JSON stringified
  };

  try {
    const response = await fetch(baseUrl + apiName, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getApiData = async (apiName) => {
  const options = {
    method: "GET",
  };

  try {
    const response = await fetch(baseUrl + apiName, options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const responseData = await response.json();
    console.log("Response Data:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const putData = async (apiName, data) => {
  var options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // Ensure data is JSON stringified
  };

  try {
    const response = await fetch(baseUrl + apiName, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getData = async (apiName, params = {}) => {
  console.log("getapiData", apiName);
  const queryString = new URLSearchParams(params).toString();
  const url = queryString
    ? `${baseUrl}${apiName}?${queryString}`
    : `${baseUrl}${apiName}`;

  var options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(url, options);
    console.log("responseresponse", response);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
